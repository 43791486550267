var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "purchaseValueProposition " + _vm.className },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "value mb-4 mb-xxl-0",
              attrs: { sm: "6", xxl: "3" },
            },
            [
              _c("div", { staticClass: "valueIcon" }, [_c("PurchaseBed")], 1),
              _c("div", { staticClass: "valueText" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.totalPropertiesCount) +
                      " hotel, B&B e appartamenti in Italia e nel mondo"
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "value mb-4 mb-xxl-0",
              attrs: { sm: "6", xxl: "3" },
            },
            [
              _c("div", { staticClass: "valueIcon" }, [_c("PurchaseSpin")], 1),
              _c("div", { staticClass: "valueText" }, [
                _c("p", [
                  _vm._v(
                    "Disponibilità in tempo reale prenotabili con pochi click"
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "value mb-4 mb-xxl-0",
              attrs: { sm: "6", xxl: "3" },
            },
            [
              _c(
                "div",
                { staticClass: "valueIcon" },
                [_c("PurchaseCalendar")],
                1
              ),
              _c("div", { staticClass: "valueText" }, [
                _c(
                  "p",
                  [
                    _c("CopyScadenza", {
                      attrs: {
                        default:
                          "Puoi prenotare e viaggiare entro {value} mesi da ora",
                        promoMonths:
                          "Puoi prenotare e viaggiare entro {value} mesi da ora",
                        promoFixed:
                          "Puoi prenotare e viaggiare entro il {value}",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "value mb-4 mb-xxl-0",
              attrs: { sm: "6", xxl: "3" },
            },
            [
              _c(
                "div",
                { staticClass: "valueIcon" },
                [_c("PurchaseArrowCircle")],
                1
              ),
              _c("div", { staticClass: "valueText" }, [
                _c("p", [
                  _vm._v(
                    "Tantissime disponibilità rimborsabili con riattivazione automatica del voucher"
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
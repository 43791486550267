<template>

    <div id="checkout" data-e2e="checkout">

    <script v-if="!this.mixinGetIntegrationInfo().integration" v-html="jsonld" type="application/ld+json"></script>

    <b-container fluid id="main">
      <Breadcrumbs :activeStep="mixinGetIntegrationInfo().mode == 'booking' ? 3 : 2"/>
      <b-row>
        <b-col sm="12" lg="8" xxl="9">
          <div id="content">
            <Reservation
              v-if="mixinGetIntegrationInfo().mode == 'booking'"
              :bookingData="bookingData"
              :guest="{}"
              :confirmcode="''"
              :itinerary_id="''"
            />
            <div v-else>
              <Boxset :boxsetId="checkoutBoxsetId" />
            </div>
          </div>
        </b-col>
        <b-col sm="12" lg="4" xxl="3">
            <SidebarCheckoutSso v-if="mixinGetIntegrationInfo().sso" v-on:fb-px-initiate-checkout="fbPxInitiateCheckout" v-on:sidebar-order-confirmed="sidebarOrderConfirmed"/>
            <SidebarCheckoutBooking v-else-if="mixinGetIntegrationInfo().mode == 'booking'" v-on:fb-px-initiate-checkout="fbPxInitiateCheckout" v-on:sidebar-order-confirmed="sidebarOrderConfirmed"/>
            <SidebarCheckoutPublic v-else v-on:fb-px-initiate-checkout="fbPxInitiateCheckout" v-on:sidebar-order-confirmed="sidebarOrderConfirmed"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Vue from "vue";
  import Breadcrumbs from '../blocks/topbar/breadcrumbs'

  export default {
    name: 'checkout',
    metaInfo() {
      return {
        title: this.boxset.boxset_title,
        meta: [
          { vmid: 'metaDescription', name: 'Description', content: 'Scopri le disponibilità di ' + this.boxset.boxset_title + ' e acquista il tuo voucher per viaggiare' },
          { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: 'Scopri le disponibilità di ' + this.boxset.boxset_title + ' e acquista il tuo voucher per viaggiare' },
          { vmid: 'ogTitle', property: 'og:title', content: this.boxsetMeta.boxset_title },
          { vmid: 'ogType', name: 'og:type', content: 'website' },
          { vmid: 'ogUrl', name: 'og:url', content: this.boxsetMeta.pageUrl},
          { vmid: 'ogImage', name: 'og:image', content: this.boxsetMeta.imageUrl},
          { vmid: 'ogImageWidth', name: 'og:image:width', content: '474' },
          { vmid: 'ogImageHeight', name: 'og:image:height', content: '591' },
          { vmid: 'ogDescription', name: 'og:description', content: this.boxsetMeta.description },
        ]
      }
    }, // metaInfo
    components: {
      Breadcrumbs,
      SidebarCheckoutSso: () => import('@/components/blocks/sidebar/sidebar-checkout-sso').then(function(SidebarCheckoutSso) {
        window.console.log('async SidebarCheckoutSso Component loaded')
        return SidebarCheckoutSso
      }),
      SidebarCheckoutPublic: () => import('@/components/blocks/sidebar/sidebar-checkout-public').then(function(SidebarCheckoutPublic) {
        window.console.log('async SidebarCheckoutPublic Component loaded')
        return SidebarCheckoutPublic
      }),
      SidebarCheckoutBooking: () => import('@/components/blocks/sidebar/sidebar-checkout-booking').then(function(SidebarCheckoutBooking) {
        window.console.log('async SidebarCheckoutBooking Component loaded')
        return SidebarCheckoutBooking
      }),
      Reservation: () => import('@/components/blocks/reservation').then(function(Reservation) {
        window.console.log('async Reservation Component loaded')
        return Reservation
      }),
      Boxset: () => import('@/components/blocks/boxset/boxset').then(function(Boxset) {
        window.console.log('async Boxset Component loaded')
        return Boxset
      }),
    },
    props:{
      boxsetIdFromUrl: {
        type: String,
        default: '',
      },
    },
    data() {

      return {
        boxset: {},
      }
    },
    computed: {
      bookingData: {
        get() { return this.$store.state.bookingData },
        set(value) { this.mixinSendMutation('setBookingData',value) }
      },
      checkoutBoxsetId:
      {
        get() {
          return parseInt((this.boxsetIdFromUrl !== '' ? this.boxsetIdFromUrl : this.bookingData.boxset_id))
        },
        set() {}
      },
      boxsetMeta:{
        get(){

          if(!this.boxset.boxset_title) return {
            'pageUrl': '',
            'imageUrl': '',
            'description': '',
          }

          let nightsText = ''
          if(this.boxset && this.boxset.nights) nightsText = (this.boxset.nights > 1)? this.boxset.nights + ' notti' : this.boxset.nights + ' notte'

          let localita = 'qualsiasi'
          localita = this.localitaDaFocus(this.boxset.focus)

          return {
            'pageUrl': process.env.VUE_APP_SITE_HOST + 'shop/' + this.boxset.focus + '-viaggi/' + this.boxset.boxset_title.toLowerCase().replace(/ +/g, "-").replace('---', '-') + '/' + this.boxset.boxset_id,
            'imageUrl': this.$config.boxsets_images_basepath + this.boxset.image,
            'description': 'Acquistando questo voucher potrai prenotare un soggiorno di ' + nightsText + ' in una qualsiasi località ' + localita + ' di tua preferenza. Il Salabam è utilizzabile ' + this.$config.guiSettings.vouchersValidity.validityText + ', potrai quindi acquistare ora e scegliere con calma la destinazione  la struttura in cui effettuare il soggiorno',
          }

        },
        set(){},
      }, // boxsetMeta


      jsonld: {
        get(){

          const jsonld = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": this.boxset.boxset_title,
            "image": [
              this.boxsetMeta.imageUrl,
            ],
            "description": this.boxsetMeta.description,
            "sku": this.boxset.boxset_id,
            //"mpn": "925872",
            "brand": {
              "@type": "Brand",
              "name": "Salabam"
            },
            /*"review": {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "4",
                "bestRating": "5"
              },
              "author": {
                "@type": "Person",
                "name": "Fred Benson"
              }
            },*/
            /*"aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.4",
              "reviewCount": "89"
            },*/
            "offers": {
              "@type": "Offer",
              "url": window.location.href,
              "priceCurrency": "EUR",
              "price": this.boxset.public_price,
              "priceValidUntil": this.dateTodayPlusOneYear(),
              "itemCondition": "https://schema.org/NewCondition",
              "availability": "https://schema.org/InStock"
            }
          }

          return jsonld

        },
        set(){},
      }

    }, // computed
    mounted() {


      if(this.mixinGetIntegrationInfo().mode == 'boxset'){
        // TODO
        // per gestire accesso diretto tramite url al checkout
        // l'if con il mixin è una pezza
        // mixinBoxsetSelected va a sovrascrivere bookingdata
        // principalmente il problema è dovuto da dates.checkout (che è sempre vuoto)
        // quando si seleziona una camera, bookingdata viene aggiornato (da roomSelected o come si chiama)
        // con i dettagli della prenotazione da details (detailsSearchParams)
        let boxset = this.mixinGetBoxsetFromConfig(this.checkoutBoxsetId).boxset

        if(!boxset){
          this.mixinGoTo('not-found', {})
          return
        }


        this.mixinSendMutation('setFocus', boxset.focus)
        this.mixinSendMutation('setActiveBand', boxset.band)
        this.mixinSendMutation('setNights', boxset.nights)
        this.mixinBoxsetSelected(boxset)

        this.boxset = boxset

        this.bookingData.focus = boxset.focus
        this.bookingData.band = boxset.band
        this.bookingData.nights = boxset.nights
        this.bookingData.public_price = boxset.public_price

      }


      if(this.$gtm) this.$gtm.trackEvent({
        event: 'customEvent',
        category: 'userBehaviour',
        action: 'initiateCheckout',
        label: (Vue.prototype.$config.integration ? Vue.prototype.$config.integration.email : ''),
        value: this.bookingData.live_price,
      })

      if(this.$gtm) this.$gtm.trackEvent({
        event: 'customEvent', // Event type [default = 'interaction'] (Optional)
        category: 'partnerBehaviour',
        action: 'initiateCheckout',
        label: (Vue.prototype.$config.integration ? Vue.prototype.$config.integration.company : ''),
        value: this.bookingData.live_price,
      })

      if(window.LogRocket) window.LogRocket.track('initiateCheckout') // TODO questa è la pagina prodotto

      if(this.mixinGetIntegrationInfo(0).mode == 'boxset'){
        if(window.fbq) window.fbq('track', 'ViewContent', {
          value: this.boxset.public_price,
          currency: 'EUR',
          content_ids: [this.boxset.boxset_id],
          content_type: 'product',
          contents: [{'id': this.boxset.boxset_id, 'quantity': '1', 'item_price': this.boxset.public_price}],
          content_name: this.boxset.boxset_title
        });
      }

    },
    methods: {
      fbPxInitiateCheckout(){

        if(this.mixinGetIntegrationInfo(0).mode == 'boxset'){
          // InitiateCheckout acquisto cofanetto

          if(window.fbq) window.fbq('track', 'AddToCart', {
            content_name: this.boxset.boxset_title,
            content_category: 'Salabam ' + this.boxset.focus + ' ' + this.boxset.band,
            content_ids: [this.boxset.boxset_id],
            content_type: 'product',
            value: this.boxset.public_price,
            currency: 'EUR'
          });

          if(window.fbq) window.fbq('track', 'InitiateCheckout', {
            content_name: this.boxset.boxset_title,
            content_category: 'Salabam ' + this.boxset.focus + ' ' + this.boxset.band,
            content_ids: [this.boxset.boxset_id],
            content_type: 'product',
            value: this.boxset.public_price,
            currency: 'EUR'
          });

        }

      }, // fbPxInitiateCheckout

      sidebarOrderConfirmed: function(data){

        let qty = 1
        if(data && data.qty) qty = data.qty


        if(this.mixinGetIntegrationInfo(0).mode == 'boxset'){
          // fb px - qualsiasi acquisto di cofanetto

          /*if(window.fbq) window.fbq('track', 'Purchase', {
            contents: [{id: this.boxset.boxset_id, quantity: qty}],
            content_name: this.boxset.boxset_title,
            content_category: 'Salabam ' + this.boxset.focus + ' ' + this.boxset.band,
            content_type: 'product',
            value: this.boxset.public_price*qty,
            currency: 'EUR',
            content_ids: [this.boxset.boxset_id],
            num_items: qty
          });*/

        } // fb px - qualsiasi acquisto di cofanetto


        if(this.mixinGetIntegrationInfo(0).mode == 'boxset' && this.mixinGetIntegrationInfo(0).sso){
          // fb px - solo acquisto cofanetto welfare

          if(window.fbq) window.fbq('trackCustom', 'integratedOrder', {

            /*content_ids: [this.boxset.boxset_id],
            content_type: 'product',
            value: this.boxset.public_price*qty,
            currency: 'EUR'*/

            contents: [{id: this.boxset.boxset_id, quantity: qty}],
            content_name: this.boxset.boxset_title,
            content_category: 'Salabam ' + this.boxset.focus + ' ' + this.boxset.band,
            content_type: 'product',
            value: this.boxset.public_price*qty,
            currency: 'EUR',
            content_ids: [this.boxset.boxset_id],
            num_items: qty

          });

        } // fb px - solo acquisto cofanetto welfare


        if(this.mixinGetIntegrationInfo(0).mode == 'booking'){
          // fb px - qualsiasi prenotazione

          if(window.fbq) window.fbq('trackCustom', 'Booking', {
            content_ids: [this.$config.integration.salabam.boxset_id],
            content_type: 'product',
            value: this.$config.integration.salabam.public_price,
            currency: 'EUR'
          });

        } // // fb px - qualsiasi prenotazione


      }, // sidebarOrderConfirmed

      localitaDaFocus: function(focus){
        // metodo duplicato a mano su checkout.vue e boxset.vue
        switch(focus){

          case 'italia':
            return 'italia';

          case 'europa':
          case 'ita-eu':
            return 'europea';

          case 'mondo':
            return 'extra-europea';

          case '':
          case 'all':
          default:
            return 'qualsiasi';
        }

      }, // localitaDaFocus

      dateTodayPlusOneYear: function (){

        var year  = new Date().getFullYear()
        var month = new Date().getMonth()
        var day   = new Date().getDate()
        var date  = new Date(year + 1, month, day)

        return date

      },

    }, // methods
  }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    
    #checkout
    {
      padding-top:0;
      #main
      {
        display:block;
      }
      .breadcrumbs
      {
        margin-bottom:0.5rem;
        justify-content: center;
        width:100%;
        @include media-breakpoint-up(lg)
        {
          margin-bottom:2rem;
          //margin-bottom:3rem;
        }
      }      
      #content
      {
        min-width: 100%;
        flex:100%;
      }
      #sidebar
      {
        background:transparent;
        min-width: 100%;
        flex:100%;
        .sidebar-inner
        {
          background:transparent;
        }
      }

      .checkoutBookingEngine form
      {
        display:block !important;
      }
      
      .checkoutHead
      {
        text-align:center;
      }
      .checkoutBack
      {
        text-align: center;
        margin-bottom:$grid-gutter-width/2;

        @include media-breakpoint-up(lg) {
          text-align: left;
        }
      }

      @include media-breakpoint-up(lg)
      {
        #main
        {
          padding-left:$grid-gutter-width;
          padding-right:$grid-gutter-width;
        }
        padding-top:$grid-gutter-width/2;
      }

      //refines mobile
      @include media-breakpoint-down(xs)
      {
        //elementi in sidebar a sinistra
        .boxsetLeftColumn
        {
          .voucherLeft,
          //.checkoutBookingEngine,
          .engineDesktop,
          .voucherInfo
          {
            display: none;
          }
        }

        //elementi in corpo pagina
        .boxsetRightColumn
        {
          .voucherAlert,
          .valuePropositionsCheckoutWrapper
          {
            display:none;
          }
          .infoBoxsetPurchaseWrapper
          {
            margin-top:1rem;
            font-size: 13px;
          }
          .boxsetResume
          {
            align-items: center;
            justify-content: center;
            .boxsetIcon
            {
              width:20px;
              height:auto;
            }
            .boxsetName
            {
              font-size:16px;
            }
            strong,
            .expire,
            .salaBadge
            {
              font-size: 14px;
            }
            .expire
            {
              align-items: center;
              justify-content: center;
              margin:auto;
              text-align: center;
              margin-top:1rem;
              margin-bottom:1rem;
              font-size: 12px;
            }
          }
          .salaBadge
          {
            padding:3px 10px;
          } 
          .purchaseValueProposition
          {
            .valueIcon
            {
              width:24px;
              min-width:24px;
              max-width:24px;
            }
            .valueText
            {
              padding-left:0.5rem;
              font-size: 12px;
            }
          }       
        }

        //elementi sidebar a destra
        #sidebar
        {
        }
      }


      //refines ipad portrait
      @include media-breakpoint-only(md)
      {
        .breadcrumbs
        {
          padding:0 0 0 0;
          margin:1.5rem 0 0 0;
        }
        .termsShortExpanded
        {
          font-size: 14px;
        }
        .customInputBlock
        {
          margin-bottom:0;
        }
        .boxset
        {
          .boxsetResume
          {
            strong
            {
              font-size: 16px;
            }
            .expire
            {
              margin-top:0.5rem;
            }
          }
          .boxsetLeftColumn
          {
            .purchasingText
            {
              margin-top:2rem;
            }
            .checkoutVoucherBookinEngine
            {
              flex:1;
              min-width:calc(100% - 80px);
              
              #search-form-datepicker,
              #search-form-nights,
              #search-form-occupancy
              {
                width: 100%;
                max-width: 100% !important;
              }
            }
          }
        }

        .prefooter-blocks-large
        {
          .block-wrap
          {
            flex:100%;
            max-width: 100%;
            &:not(:last-child)
            {
              padding-bottom:2rem;
            }
          }
        }
      }

    }

    #sidebarCheckout
    {
      background:$white;//$gold;
      //box-shadow:$box-shadow;
      //border-radius:$border-radius;
      padding:0;      

      .sidebarStep
      {
          padding:$grid-gutter-width/2 $grid-gutter-width/2;
          margin-bottom:$grid-gutter-width;
          border-radius:$border-radius;//$border-radius-large;
          box-shadow:$box-shadow;
          &:not(:last-child)
          {
            border-bottom:1px solid rgba($black,0.25);
          }
          &.bgWhite
          {
            background-color:$white;
            border:1px solid $light-gray;
          }
          &.bgDark
          {
            background-color:$dark-blue;
            *
            {
              color:$white;
              border-color:$white;
            }
            &>hr
            {
              display:block;
              border-top:1px solid $white;
              margin:$grid-gutter-width/2 0;
              width:calc(100% + #{$grid-gutter-width});
              margin-left: -$grid-gutter-width/2;
            }
            &>div hr
            {
              border-top:2px solid $white;
              opacity:0.5;
            }
          }
      }
      
      .sidebarStepTitle
      {
          color:$black;
          text-align:center;
          margin-bottom:0;
          font-weight:400;
          font-size: 14px;
          @include media-breakpoint-up(md)
          {
            font-size: 16px;
          }
          @include media-breakpoint-up(xl)
          {
            font-size: 18px;
          }
      }

      .sidebarRow
      {
          display:flex;
          justify-content:space-between;
          align-items:flex-start;
          //&:not(:last-child)
          //{
              margin-bottom:$grid-gutter-width/4;
          //}
      }

      .sidebarBlockTitle
      {
          color:$black;
          display:block;
          width:100%;
          text-align:center;
      }

      .svgInfo
      {
          order:1;
          //width:14px;
          margin:0 8px 0 0;
      }

      @include media-breakpoint-down(xs)
      {
        .sidebarStep
        {
          padding:$grid-gutter-width/4 $grid-gutter-width/4;
          font-size: 13px;
          .customInputBlock
          {
            margin-bottom:0;
          }
          &>hr
          {
            margin:$grid-gutter-width/3 0 !important;
            width:calc(100% + #{$grid-gutter-width/2}) !important;
            margin-left: -$grid-gutter-width/4 !important;
          }          
        }       
      }

        .sidebarStep.bgGray
        {
            border:1px solid #F2F2F2;   
        }
  }
</style>
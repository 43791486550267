var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "60",
        height: "50",
        viewBox: "0 0 60 50",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M43.7665 10.2361C46.5931 10.2361 48.8845 7.94465 48.8845 5.11804C48.8845 2.29142 46.5931 0 43.7665 0C40.9399 0 38.6484 2.29142 38.6484 5.11804C38.6484 7.94465 40.9399 10.2361 43.7665 10.2361Z",
          fill: "#0077CA",
        },
      }),
      _c("path", {
        attrs: {
          d: "M43.5786 21.0964C43.7659 21.2836 43.9531 21.5333 44.1404 21.8454L44.5149 21.7205L52.2543 19.3488V16.6649C52.2543 16.4777 52.2543 16.2904 52.1919 16.1032C52.0047 13.9811 50.5691 12.2959 48.6343 11.5469C47.2611 12.6079 45.5759 13.2321 43.7659 13.2321C41.9558 13.2321 40.2706 12.6079 38.8975 11.5469C36.9626 12.2959 35.5271 13.9811 35.3398 16.1032C35.3398 16.2904 35.3398 16.4777 35.3398 16.6649V19.1615L39.6465 19.3488C41.1444 19.2863 42.58 19.9729 43.5786 21.0964Z",
          fill: "#0077CA",
        },
      }),
      _c("path", {
        attrs: {
          d: "M58.3704 22.409C57.559 21.5352 56.2483 21.1607 55.1248 21.5352L45.4505 24.5311C45.4505 24.7808 45.4505 25.0305 45.4505 25.2177C45.3257 27.964 43.3284 30.2733 40.6445 30.8351L40.0204 30.9599C36.4627 31.7089 32.9051 32.1458 29.285 32.2706L26.3515 32.3954C25.4152 32.3954 24.6663 31.7089 24.6663 30.8351C24.6663 29.9613 25.3528 29.1499 26.2266 29.1499L29.1601 29.025C32.593 28.9002 36.0258 28.4633 39.3338 27.7767L39.958 27.6519C41.2063 27.4022 42.0801 26.3412 42.1425 25.0929C42.2049 24.3439 41.9552 23.6573 41.4559 23.0956C40.9566 22.5339 40.27 22.2218 39.521 22.2218L23.9797 21.6601C22.2321 21.5976 20.5469 22.097 19.1113 23.0956L6.19141 32.3954L19.5482 42.5067L32.593 43.8174C34.9023 44.0671 37.1493 43.3805 38.9593 42.0074L58.0583 27.1526C58.8073 26.5908 59.2442 25.7795 59.3066 24.8432C59.3066 23.907 58.9945 23.0332 58.3704 22.409Z",
          fill: "#0077CA",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.19487 34.5781L0.262725 39.7586C-0.174181 40.3827 -0.0493504 41.1941 0.512385 41.6934L11.0605 49.6826C11.6847 50.1195 12.4961 49.9946 12.9954 49.4329L16.9275 44.2525L4.44453 34.7654L4.19487 34.5781Z",
          fill: "#0077CA",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "checkout", "data-e2e": "checkout" } },
    [
      !this.mixinGetIntegrationInfo().integration
        ? _c("script", {
            attrs: { type: "application/ld+json" },
            domProps: { innerHTML: _vm._s(_vm.jsonld) },
          })
        : _vm._e(),
      _c(
        "b-container",
        { attrs: { fluid: "", id: "main" } },
        [
          _c("Breadcrumbs", {
            attrs: {
              activeStep:
                _vm.mixinGetIntegrationInfo().mode == "booking" ? 3 : 2,
            },
          }),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "12", lg: "8", xxl: "9" } }, [
                _c(
                  "div",
                  { attrs: { id: "content" } },
                  [
                    _vm.mixinGetIntegrationInfo().mode == "booking"
                      ? _c("Reservation", {
                          attrs: {
                            bookingData: _vm.bookingData,
                            guest: {},
                            confirmcode: "",
                            itinerary_id: "",
                          },
                        })
                      : _c(
                          "div",
                          [
                            _c("Boxset", {
                              attrs: { boxsetId: _vm.checkoutBoxsetId },
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]),
              _c(
                "b-col",
                { attrs: { sm: "12", lg: "4", xxl: "3" } },
                [
                  _vm.mixinGetIntegrationInfo().sso
                    ? _c("SidebarCheckoutSso", {
                        on: {
                          "fb-px-initiate-checkout": _vm.fbPxInitiateCheckout,
                          "sidebar-order-confirmed": _vm.sidebarOrderConfirmed,
                        },
                      })
                    : _vm.mixinGetIntegrationInfo().mode == "booking"
                    ? _c("SidebarCheckoutBooking", {
                        on: {
                          "fb-px-initiate-checkout": _vm.fbPxInitiateCheckout,
                          "sidebar-order-confirmed": _vm.sidebarOrderConfirmed,
                        },
                      })
                    : _c("SidebarCheckoutPublic", {
                        on: {
                          "fb-px-initiate-checkout": _vm.fbPxInitiateCheckout,
                          "sidebar-order-confirmed": _vm.sidebarOrderConfirmed,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
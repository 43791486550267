<template>
  <div id="checkout">

    <Breadcrumbs :activeStep="3"/>

    <b-row  v-if="canceling" class="text-center">
      <b-col>
        Attendi un attimo, stiamo cancellando la prenotazione.
        <br/><br/>
        <b>Non lasciare questa pagina.</b>
        <br/><br/>
        <Spinner />
      </b-col>
    </b-row>

    <b-row  v-if="cancelMessage !== ''" style="flex:1">
      <b-col>
        <div class="text-center">
          <b-alert class="d-inline" variant="success" show>{{cancelMessage}}</b-alert>
          <br/>
          <p v-if="Object.keys(refundDiscountCode).length">
          Abbiamo emesso il buono sconto <b>{{refundDiscountCode.code}}</b> del valore di <b>{{refundDiscountCode.valueNominal}}€</b> da utilizzarsi entro il <b>{{refundDiscountCode.expireAt}}</b> (gg-mm-aa) per una nuova prenotazione.<br/><br/>
          Questo buono ti è stato inviato anche per email insieme alla "conferma di cancellazione" di questa prenotazione.<br/><br/>
          <br/>
          <a :href="refundDiscountCode.link"><b-button variant="" >Usa il buono adesso</b-button></a>
          </p>
          <br><br><br>
        </div>
      </b-col>
    </b-row>

    <b-container fluid id="main" v-else-if="salabam !== undefined">
        
        <Spinner v-if="loading" />

        <b-row style="flex:1">
            <b-col sm="12" md="8" xxl="9">
                <div id="content">
                    <Reservation
                      v-if="!loading && !canceling && cancelMessage == ''"
                      :bookingData="bookingData"
                      :guest="guest"
                      :confirmcode="''"
                      :itinerary_id="''"
                      :reservation="reservation"
                    />
                </div>
            </b-col>


            <!-- riepilogo cofanetto usato per la prenotazione -->
            <b-col sm="12" md="4" xxl="3" v-if="!canceling">
              <div class="confirmSidebar">
                <div v-if="!loading">
                  <h5 class="confirmSidebarTitle text-left">Per fare questa prenotazione è stato utilizzato il:</h5>
                  <br>
                  <div class="panel-white">
                    <BoxsetResume
                      :focus="salabam.focus"
                      :band="salabam.band"
                      :nights="salabam.nights"
                      :price="parseFloat(salabam.public_price)"
                      :bookingHidePrice="(this.$config.guiSettings.bookingHidePrice)"
                    />

                    <p class="mt-4">
                      Titolare: {{salabam.beneficiario}}
                      <br>
                      Codice Salabam: {{salabam.confirmcode}}
                      <br>
                      Valido fino al: {{salabam.expireAt}}
                      <br>
                    </p>
                    <p class="small">
                      Ove possibile in base alle policy di cancellazione e in caso di cancellazione di questa prenotazione, il voucher Salabam verrà riattivato e sarà valido fino a questa data (il check-in dovrà essere fatto entro questa data
                    </p>
                  </div>
                </div>


                <!-- riepilogo upselling, se presente -->
                <div v-if="!loading && Object.keys(upselling).length > 0">
                  <div class="panel-white">

                    <h5 class="confirmSidebarTitle text-left">Servizi aggiunti:</h5>

                    <p class="small">
                      Per questa prenotazione sono stati richiesti e saldati tramite carta di credito dei servizi aggiuntivi
                    </p>

                    <p class="mt-4">
                      Importo pagato: {{upselling.amount}} €
                      <br>
                      Nome sulla carta: {{upselling.buyerNameSurname}}
                      <br>
                      Voce in estrattoconto: {{upselling.statementDescriptor}}
                      <br>
                    </p>

                  </div>
                </div>


                <div v-if="!loading" class="deleteReservation">
                  <div class="panel-white">
                    <div v-if="canceling">
                      Attendi un attimo, stiamo cancellando la prenotazione.
                      <br/><br/>
                      <b>Non lasciare questa pagina.</b>
                      <br/><br/>
                      <Spinner />
                    </div>
                    
                    <div v-if="!canceling">
                      <p v-if="cancelMessage !== ''">
                        {{cancelMessage}}
                      </p>
                      
                      <div v-else>
                        <h5 class="confirmSidebarTitle text-center">Policy di cancellazione</h5>
                        <br>

                        <div v-if="reservation.refundable">
                          <!-- cancellabile ma non sono più in tempo -->
                          <div v-if="refundableBeforeDate < Date.now()">
                            <p>Questa prenotazione poteva essere modificata entro il <b>{{reservation.refundableBefore}}</b> (gg-mm-aaaa hh:mm:ss)</p>
                          </div>

                          <!-- cancellabile e sono ancora in tempo -->
                          <div v-else>
                            <p class="text-left">
                              Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il: <b>{{reservation.refundableBefore}}</b> (GG-MM-AAAA).
                            </p>

                            <div v-if="Object.keys(upselling).length == 0">
                              <!-- se c'è upselling non è possibile cancellara la prenotazione in autonomia, non gestisco ancora lo storno del pagamento con cc o l'emissione di un buono sconto -->
                              <p class="text-left">
                                Se lo farai, verrà riattivato il voucher Salabam originario indicato in questa pagina con lo stesso valore e la stessa data di scadenza (il viaggio deve essere effettuato entro la data di validità).
                              </p>

                              <b-button variant="danger" size="sm" v-b-modal.sidebar-modal-cancel>Cancella la prenotazione</b-button>

                            </div>
                            <div v-else>
                              <p class="text-center">
                                <br/>
                                <i>Essendo stati acquistati dei servizi aggiuntivi, per cancellare questa prenotazione dovrai contattare l'assistenza.</i>
                              </p>
                            </div>


                            <b-modal id="sidebar-modal-cancel" title="Cancella la prenotazione" hide-footer size="lg">
                              <div class="mt-4 mb-4 ml-2 mr-2 text-center">
                              <p v-if="!loading">
                                Cancellando la prenotazione il tuo codice Salabam verrà subito riattivato e potrai effettuare una nuova prenotazione.
                                <br/><br/>
                                <b>Sei sicuro di voler cancellare questa prenotazione?</b>
                                <br/><br/>
                                <b-button variant="danger" @click="cancelReservation">Si, cancella la prenotazione</b-button>
                              </p>
                              <p v-else>
                                Attendi un attimo, stiamo cancellando la prenotazione.
                                <br/><br/>
                                <b>Non lasciare questa pagina.</b>
                                <br/><br/>
                                <Spinner />
                              </p>
                              </div>
                            </b-modal>
                          </div>
                      </div>
                      <div v-else>
                        <p>Questa prenotazione non è cancellabile nè modificabile nè rimborsabile.</p>
                        <!--<p v-if="this.$route.name != 'confirm'">
                            Alla luce delle incertezze causate da Covid-19 ti consigliamo di scegliere soluzioni di tipo Rimborsabile.
                        </p>-->
                      </div>

                    </div>         
                  </div>
                  </div>

                  <div class="panel-gray">
                    <h5 class="confirmSidebarTitle text-left">Desideri acquistare un altro voucher Salabam?</h5>
                    <br>
                    <b-button @click="mixinGoTo('home',{})" size="sm">Torna alla Home</b-button>
                    <br>
                    <br>
                    <div class="d-flex">
                      <div class="svgHands"><SvgHands /></div>
                      <p class="small text-left ml-3">
                        Per acquistare un altro voucher Salabam con il tuo credito di welfare aziendale, torna sul tuo portale welfare e segui il procedimento indicato.
                      </p>
                    </div>
                  </div>

                </div>

              </div>
            </b-col>
        </b-row>
    </b-container>
  </div>
</template>

<script>
  import Vue from "vue";
  import Api from '../../api'
  import Spinner from '../atoms/spinner'
  import Breadcrumbs from '../blocks/topbar/breadcrumbs'
  import BoxsetResume from '../blocks/boxset/boxset-resume'
  import SvgHands from '../svg/hands'

  export default {
    name: 'checkout',
    metaInfo(){
      return {
        title: 'checkout',
        meta: [
          { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: this.prerenderStatusCode }
        ]
      }
    },
    components: {
      Breadcrumbs,
      Spinner,
      BoxsetResume,
      SvgHands,
      Reservation: () => import('@/components/blocks/reservation').then(function(Reservation) {
        window.console.log('async Reservation Component loaded')
        return Reservation
      }),
    },
    props:{
      confirmcode: String,
      itinerary_id: String,
      prerenderStatusCode: {
        type: String,
        default: '200',
      },
    },
    data() {
      return {
        loading: true,
        canceling: false,
        bookingData: {},
        reservation: {},
        salabam: {},
        guest: {},
        cancelMessage: '',
        refundDiscountCode: [],
        upselling: {},
      }
    },
    computed: {
      refundableBeforeDate: function(){
        let fullDate = (this.bookingData.refundableBefore ? this.bookingData.refundableBefore : '')
        let date = fullDate.split(' ')[0]
        let hour = fullDate.split(' ')[1]
        let dateString = Date.parse(date.split('-')[2]+'-'+date.split('-')[1]+'-'+date.split('-')[0]+'T'+hour)
        // 23-08-2020 00:01:00 -> 2011-10-10T14:48:00
        return dateString
      },
    },
    mounted() {
      
      if(this.$gtm) this.$gtm.trackEvent({
        event: 'customEvent',
        category: 'userBehaviour',
        action: 'initiateCheckout',
        //label: Vue.prototype.$config.integration.email,
        value: this.bookingData.public_price,
      })

      if(this.$gtm) this.$gtm.trackEvent({
        event: 'customEvent', // Event type [default = 'interaction'] (Optional)
        category: 'partnerBehaviour',
        action: 'initiateCheckout',
        //label: Vue.prototype.$config.integration.company,
        value: this.bookingData.public_price,
      })

      if(window.LogRocket) window.LogRocket.track('initiateCheckout')

      let _this = this

      Api
        .getReservation({
          confirmcode: _this.confirmcode,
          itinerary_id: parseInt(_this.itinerary_id)
        })
        .then((response) => {
          _this.bookingData = response.bookingData
          _this.guest = response.guest
          _this.reservation = response.reservation
          _this.salabam = response.salabam
          _this.loading = false
          _this.mixinSendMutation('setIntegration',undefined)
          //_this.mixinSendMutation('setToken','')
          Vue.prototype.$config.integration = undefined
          //Vue.prototype.$config.token = ''

          if(response.upselling) _this.upselling = response.upselling

        })
    },
    methods: {
        cancelReservation(){

          let _this = this
          _this.canceling = true

          window.scrollTo(0,0)
          
          Api
            .cancelReservation({
                confirmcode: _this.reservation.confirmcode,
                itinerary_id: _this.reservation.itinerary_id,
                bookingData: _this.bookingData,
                salabam: _this.salabam,
            })
            .then((response) => {
              _this.$bvModal.hide('modal-cancel')
              _this.canceling = false

              // response false = http != 200
              if(response){
                _this.cancelMessage = response.msg
                if(response.refundDiscountCode) _this.refundDiscountCode = response.refundDiscountCode

                if(window.fbq) window.fbq('trackCustom', 'CanceledReservation', {
                  content_ids: [_this.salabam.boxset_id],
                  content_type: 'product',
                  value: _this.salabam.public_price,
                  currency: 'EUR'
                });

              }

            })
        },
    }
  }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    #app.reservation
    {
      background-color:$light-gray;
    }
    #checkout
    {
      padding-top:0;
      .breadcrumbs
      {
        margin-bottom:2rem;
        justify-content: center;
        width:100%;
        @include media-breakpoint-up(lg)
        {
          margin-bottom:3rem;
        }
      }      
      #content
      {
        margin:auto;
        margin-top:0;
      }
      @include media-breakpoint-up(lg)
      {
        #sidebar {
          padding-right:0;
          //padding-left:$grid-gutter-width/2;
        }
      }
      @include media-breakpoint-up(xl)
      {
        #sidebar {
          //padding-left:$grid-gutter-width;
        }
      }
      @include media-breakpoint-up(xl)
      {
        padding-top:$grid-gutter-width;
      }
      #main
      {
        padding-top:40px !important;
        margin-top:0 !important;
        @include media-breakpoint-up(md)
        {
          padding-top:0 !important;
        }
      }
      @include media-breakpoint-down(md)
      {
        #main
        {
          margin-left:0;
          margin-right:0;
        }
      }

      .checkin-checkout-box
      {
        display:flex;
        &>div
        {
          width:50%;
        }
      }
      
      .svg-pin
      {
        margin-right:4px;
        margin-bottom:2px;
        path
        {
          fill:$black;
        }
      }

      .terms-link
      {
        color:$secondary;
        cursor:pointer;
      }

      #content
      {
        flex:100%;
        max-width: 100%;
      }

      @include media-breakpoint-up(lg)
      {
        #content
        {
          padding-left:0;
          padding-right:0;
        }
        padding-top:$grid-gutter-width/2;
      }

      .confirmSidebarTitle
      {
        color:$black;
        font-weight: normal;
      }
      .svgHands
      {
        min-width: 48px;
      }
    }

    .confirmSidebar
    {
      .boxsetResume
      {
        margin-top:-6px;
      }
      .boxsetIcon
      {
        width:22px;
        margin-right:6px;
      }
      .boxsetName
      {
        font-size: 1.15rem;
      }
      .salaBadge
      {
        font-size: 14px;
      }

      .boxsetIcon {order:1;}
      .boxsetName {order:2;}
      strong {order:3;}
      .salaBadge.boxsetBackground {order:4;}
      .salaBadge:not(.boxsetBackground) {order:5;}

      .small
      {
        line-height: 140%;
        margin-bottom:0;
      }
    }

    .deleteReservation
    {
      margin-bottom:4rem;
      text-align:center;
    }
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "confirmBoxset",
      attrs: { id: "confirm", "data-e2e": "confirmBoxset" },
    },
    [
      _vm.loading
        ? _c("Spinner")
        : _c(
            "b-container",
            { attrs: { fluid: "", id: "main" } },
            [
              _c("Breadcrumbs", { attrs: { activeStep: 3 } }),
              _vm.redirect
                ? _c("b-col", { attrs: { sm: "12" } }, [
                    !_vm.loading
                      ? _c("div", { staticClass: "text-center" }, [
                          _vm._v(
                            "\n                Verrai reindirizzato al tuo portale Welfare tra "
                          ),
                          _c("b", [_vm._v(_vm._s(_vm.redirectCounter))]),
                          _vm._v(" secondi... "),
                        ])
                      : _vm._e(),
                    _c("br"),
                  ])
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12", md: "8", xxl: "9" } }, [
                    _c("div", { attrs: { id: "content" } }, [
                      _vm.boxsets.length > 0
                        ? _c(
                            "div",
                            {
                              attrs: {
                                "data-e2e": "confirmBoxsetPurchasedItems",
                              },
                            },
                            _vm._l(_vm.boxsets, function (boxset, index) {
                              return _c(
                                "div",
                                { key: "confirmBoxset-" + index },
                                [
                                  _c("Boxset", {
                                    attrs: { boxsetObject: boxset },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("b-col", { attrs: { sm: "12", md: "4", xxl: "3" } }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.mixinGoTo("home", {})
                          },
                        },
                      },
                      [
                        _c("BtnBack", {
                          attrs: { text: "Torna alla homepage" },
                        }),
                      ],
                      1
                    ),
                    _c("br"),
                    _c("br"),
                    _c(
                      "div",
                      { staticClass: "panel panel-primary bgDark" },
                      [
                        _c("p", { staticClass: "panel-title" }, [
                          _vm._v("Il tuo acquisto"),
                        ]),
                        _c("hr"),
                        _vm._l(_vm.boxsets, function (boxset, index) {
                          return _c(
                            "div",
                            {
                              key: "confirmBoxsetSidebar-" + index,
                              staticClass: "sidebarResumeBoxset",
                            },
                            [
                              _c("span", [_vm._v(_vm._s(boxset.boxset_title))]),
                              _c("span", [
                                _vm._v(_vm._s(boxset.public_price) + " €"),
                              ]),
                            ]
                          )
                        }),
                        _c("hr"),
                        _c("div", { staticClass: "sidebarResumeBoxset" }, [
                          _c("strong", [_vm._v("Totale")]),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.order.orderTotal) + " €"),
                          ]),
                        ]),
                        _vm.mixinHasDiscount() > 0
                          ? _c("div", { staticClass: "sidebarResumeBoxset" }, [
                              _c("span", [
                                _vm._v(
                                  "Sconto speciale " +
                                    _vm._s(
                                      _vm.$config.integration
                                        .customerDisplayName
                                    ) +
                                    " -" +
                                    _vm._s(100 - 100 * _vm.mixinHasDiscount()) +
                                    "%"
                                ),
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.mixinRoundTwoDigits(
                                      _vm.order.orderTotal *
                                        (1 - _vm.mixinHasDiscount())
                                    )
                                  ) + " €"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _c("br"),
                        _vm.mixinHasDiscount() > 0
                          ? _c("div", { staticClass: "sidebarResumeBoxset" }, [
                              _c("strong", [_vm._v("Totale per te")]),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.mixinRoundTwoDigits(
                                      _vm.order.orderTotal *
                                        _vm.mixinHasDiscount()
                                    )
                                  ) + " €"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "panel panel-white" },
                      [
                        _vm.boxsets.length == 1
                          ? _c("p", { staticClass: "panel-title" }, [
                              _vm._v("Il tuo Voucher"),
                            ])
                          : _c("p", { staticClass: "panel-title" }, [
                              _vm._v("I tuoi Voucher"),
                            ]),
                        _c("hr"),
                        _vm._l(_vm.boxsets, function (boxset, index) {
                          return _c(
                            "div",
                            {
                              key: "confirmBoxsetSidebarDetail-" + index,
                              staticClass: "boxsetResumeConfirm",
                            },
                            [
                              _c("BoxsetResume", {
                                attrs: {
                                  focus: boxset.focus,
                                  band: boxset.band,
                                  nights: parseInt(boxset.nights),
                                  price: parseFloat(boxset.public_price),
                                  expireDate: boxset.dataScadenza,
                                  bookingHidePrice:
                                    _vm.$config.guiSettings.bookingHidePrice,
                                },
                              }),
                              _c("br"),
                              _c("p", [
                                _c("b", [
                                  _vm._v("Codice: "),
                                  _c("span", { staticClass: "secondary" }, [
                                    _vm._v(_vm._s(boxset.confirmcode)),
                                  ]),
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "smallText" }, [
                                  _vm._v(
                                    "Usalo per effettuare il login nell'area booking del viaggio"
                                  ),
                                ]),
                              ]),
                              _c("p", [
                                _c("b", [
                                  _vm._v("Email: "),
                                  _c("span", { staticClass: "secondary" }, [
                                    _vm._v(_vm._s(boxset.email)),
                                  ]),
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "smallText" }, [
                                  _vm._v(
                                    "Usala per effettuare il login nell'area booking del viaggio"
                                  ),
                                ]),
                              ]),
                              _c("p", [
                                _c("b", [
                                  _vm._v("Valido fino al: "),
                                  _c("span", { staticClass: "secondary" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          boxset.dataScadenza
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("br"),
                                _c("span", { staticClass: "smallText" }, [
                                  _vm._v(
                                    "La prenotazione ed il viaggio devono essere effettuati entro la data di scadenza"
                                  ),
                                ]),
                              ]),
                              _c(
                                "p",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.logoutAndRedirect(
                                            boxset.confirmcode,
                                            boxset.email
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Prenota ora!")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "termsShortContainer" }, [
    _c("h2", { staticClass: "common-title mt-0 mb-4" }, [
      _vm._v("Le principali condizioni d'uso"),
    ]),
    _c(
      "div",
      { staticClass: "termsShortModals" },
      _vm._l(_vm.terms, function (term, index) {
        return _c(
          "div",
          { key: index },
          [
            _c(
              "div",
              { staticClass: "termButton" },
              [
                _c("SvgInfoCircle"),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal",
                        value: "modalTerms" + index,
                        expression: "'modalTerms'+index",
                      },
                    ],
                    staticClass: "termTitle",
                  },
                  [_vm._v(_vm._s(term.title))]
                ),
              ],
              1
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: "modalTerms" + index,
                  size: "lg",
                  title: "Condizioni d'uso",
                  "hide-footer": "",
                },
              },
              [
                _c(
                  "b-container",
                  { staticClass: "termsModal", attrs: { fluid: "" } },
                  [
                    _c("h4", { staticClass: "termTitleModal" }, [
                      _vm._v(_vm._s(term.title)),
                    ]),
                    _c("p", {
                      staticClass: "termContent",
                      domProps: { innerHTML: _vm._s(term.content) },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "termsShortExpanded" },
      _vm._l(_vm.terms, function (term, index) {
        return _c("p", { key: index }, [
          _c("span", { staticClass: "termTitle" }, [
            _vm._v(_vm._s(term.title)),
          ]),
          _c("span", {
            staticClass: "termContent",
            domProps: { innerHTML: _vm._s(term.content) },
          }),
        ])
      }),
      0
    ),
    _c(
      "p",
      [
        _vm._v("\n    Leggi tutti i "),
        _c(
          "router-link",
          { attrs: { to: { name: "terms" }, target: "_blank" } },
          [_vm._v("Termini e Condizioni")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div id="confirm" class="confirmBoxset" data-e2e="confirmBoxset">
        <Spinner v-if="loading" />
        <b-container v-else fluid id="main">

            <Breadcrumbs :activeStep="3"/>
            
            <b-col sm="12" v-if="redirect">
                <div v-if="!loading" class="text-center">
                    Verrai reindirizzato al tuo portale Welfare tra <b>{{redirectCounter}}</b> secondi... <!--<Spinner size="sm" />-->
                </div>
                <br>
            </b-col>

            <b-row>
                <b-col sm="12" md="8" xxl="9">
                    <div id="content">
                        <div v-if="boxsets.length > 0" data-e2e="confirmBoxsetPurchasedItems">
                            <div v-for="(boxset, index) in boxsets" v-bind:key="'confirmBoxset-'+index">
                                <Boxset :boxsetObject="boxset" />
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col sm="12" md="4" xxl="3">

                    <div @click="mixinGoTo('home',{})">
                        <BtnBack :text="'Torna alla homepage'" />
                    </div>
                    
                    <br>
                    <br>

                    <!--/jp-checkout?jpToken=jptok_1611564275-->

                    <div class="panel panel-primary bgDark">
                        <p class="panel-title">Il tuo acquisto</p>
                        <hr />
                        <div v-for="(boxset, index) in boxsets" v-bind:key="'confirmBoxsetSidebar-'+index" class="sidebarResumeBoxset">
                            <span>{{boxset.boxset_title}}</span>
                            <span>{{boxset.public_price}} €</span>
                        </div>
                        <hr />
                        <div class="sidebarResumeBoxset">
                            <strong>Totale</strong>
                            <strong>{{order.orderTotal}} €</strong>
                        </div>
                        
                        <!-- NB: in conferma mostriamo un pannello leggermente semplificato per i codici sconto: per il box sconto completo vedere checkout-resume.vue -->
                        <div class="sidebarResumeBoxset" v-if="mixinHasDiscount()>0">
                            <span>Sconto speciale {{$config.integration.customerDisplayName}} -{{100-100*mixinHasDiscount()}}%</span>
                            <span>{{mixinRoundTwoDigits(order.orderTotal * (1-mixinHasDiscount()))}} €</span>
                        </div>
                        <br />
                        <div class="sidebarResumeBoxset" v-if="mixinHasDiscount() > 0">
                            <strong>Totale per te</strong>
                            <strong>{{mixinRoundTwoDigits(order.orderTotal*mixinHasDiscount())}} €</strong>
                        </div>
                    </div>

                    <div class="panel panel-white">
                        <p class="panel-title" v-if="boxsets.length == 1">Il tuo Voucher</p>
                        <p class="panel-title" v-else>I tuoi Voucher</p>
                        <hr >
                        <div v-for="(boxset, index) in boxsets" v-bind:key="'confirmBoxsetSidebarDetail-'+index" class="boxsetResumeConfirm">
                            <BoxsetResume
                                :focus="boxset.focus"
                                :band="boxset.band"
                                :nights="parseInt(boxset.nights)"
                                :price="parseFloat(boxset.public_price)"
                                :expireDate="boxset.dataScadenza"
                                :bookingHidePrice="$config.guiSettings.bookingHidePrice"
                            />
                            <br>
                            <p>
                                <b>Codice: <span class="secondary">{{boxset.confirmcode}}</span></b>
                                <br>
                                <span class="smallText">Usalo per effettuare il login nell'area booking del viaggio</span>
                            </p>
                            <p>
                                <b>Email: <span class="secondary">{{boxset.email}}</span></b>
                                <br>
                                <span class="smallText">Usala per effettuare il login nell'area booking del viaggio</span>
                            </p>
                            <p>
                                <b>Valido fino al: <span class="secondary">{{boxset.dataScadenza | formatDate}}</span></b>
                                <br>
                                <span class="smallText">La prenotazione ed il viaggio devono essere effettuati entro la data di scadenza</span>
                            </p>
                            <p class="text-center">                                
                                <b-button @click="logoutAndRedirect(boxset.confirmcode,boxset.email)">Prenota ora!</b-button>
                            </p>
                        </div>
                    </div>
                </b-col>
            </b-row>                    
        </b-container>
    </div>
</template>

<script>
    import Vue from 'vue'
    import Spinner from '../atoms/spinner'
    import BtnBack from '../atoms/btn-back'
    import Boxset from '../blocks/boxset/boxset'
    import BoxsetResume from '../blocks/boxset/boxset-resume'
    import Api from '../../api'
    import Breadcrumbs from '../blocks/topbar/breadcrumbs'

    export default {
        name: 'confirm-boxset',
        metaInfo(){
          return {
            title: 'conferma',
            meta: [
              { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: this.prerenderStatusCode }
            ]
          }
        },
        components:
        {
            Breadcrumbs,
            BtnBack,
            Spinner,
            Boxset,
            BoxsetResume,
        },
        props:
        {
            integratedOrderId: Number,
            parsedEmailId: Number,
            prerenderStatusCode: {
              type: String,
              default: '200',
            },
        },
        computed:
        {
        },
        data()
        {
            return {
                bookingData: this.$store.state.bookingData,
                basepath: Vue.prototype.$config.boxsets_images_basepath,
                order: {},
                boxsets: [],
                route: this.$router.currentRoute.name,
                config: Vue.prototype.$config,
                guest: {},
                reservation: {},
                loading: true,
                redirect: false,
                redirectCounter: 60,
            }
        },
        methods: {
            logoutAndRedirect(confirmcode,email)
            {
                clearInterval(this.counter)
                this.mixinSendMutation('setIntegration',{availability: 0})
                this.mixinSendMutation('setToken','')
                window.location.href = '/ho-un-salabam/'+email+'/'+confirmcode
            }
        },
        mounted() {
            let _this = this                        
            Api
                .confirmBoxset({
                    integratedOrderId: this.integratedOrderId,
                    parsedEmailId: this.parsedEmailId
                })
                .then((response) => {
                    _this.order = response.order
                    _this.boxsets = response.boxsets
                    _this.loading = false

                    // aggiusto il formato data per Firefox
                    response.boxsets.forEach(boxset => {
                        let dataScadenza = boxset.dataScadenza,
                            day = dataScadenza.split('-')[0],
                            month = dataScadenza.split('-')[1],
                            year = dataScadenza.split('-')[2]

                        boxset.dataScadenza = new Date(year,(month-1),day)
                    })

                    if(_this.config.integration !== undefined && _this.config.integration.orderConfirmedRedirectTo !== '')
                    {
                        _this.redirect = true
                        let counter = window.setInterval(function(){
                            if(_this.redirectCounter == 0 && _this.route == 'confirm-boxset')
                            {
                                clearInterval(counter)
                                window.location.href = _this.config.integration.orderConfirmedRedirectTo
                            }
                            else
                            {
                                _this.redirectCounter = _this.redirectCounter - 1
                            }
                        },1000)                        
                    }
                })    
                
        }
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    #confirm.confirmBoxset
    {
        min-height: 100vh;
        padding:$grid-gutter-width/2 0 $grid-gutter-width 0;

        .panel-primary.bgDark
        {
            background-color:$dark-blue;
            *
            {
                color:$white;
            }
            hr
            {
                border-color:$white;
            }
        }
        .smallText
        {
            font-size: 14px;
        }
        .purchasingText
        {
            display:none;
        }

        #main
        {
            display:block;
        }
        .breadcrumbs
        {
            margin-bottom:2rem;
            justify-content: center;
            width:100%;
            @include media-breakpoint-up(lg)
            {
            margin-bottom:3rem;
            }
        }      
        #content
        {
            min-width: 100%;
            flex:100%;
        }
        #sidebar
        {
            background:transparent;
            min-width: 100%;
            flex:100%;
            .sidebar-inner
            {
            background:transparent;
            }
        }        
        .confirmBoxsetHeading
        {
            display:flex;
            flex-wrap:wrap;
            align-items:center;
            span
            {
                display:inline-block;
                margin-right:1.5rem;
            }
        }

        /*
        .container
        {
            max-width: 1200px;
            margin:0 auto;
        }
        */

        .boxset
        {
            display:flex;
            margin:$grid-gutter-width/2 0;
        }

        .boxsetLeft
        {
            flex:1;
            max-width: 150px;
        }

        .boxsetRight
        {
            flex:1%;
            max-width: calc(100% - 150px);
            padding:0 $grid-gutter-width/2;
        }

        .boxsetTitle
        {}

        .boxsetImg
        {
            width:200px;
            max-width: 100%;
        }

        .sidebarResumeBoxset
        {
            display:flex;
            align-items: center;
            justify-content: space-between;
            //font-size: 14px;
        }
        .boxsetResumeConfirm
        {
            strong:not(.boxsetName),
            .expire
            {
                display:none;
            }
            .boxsetResume
            {
                justify-content: center;
                strong
                {
                    font-size: 16px;
                }
            }
            .salaBadge
            {
                font-size:14px;
            }
            small
            {
                line-height: 80%;
            }
        }
    }
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "40",
        height: "40",
        viewBox: "0 0 40 40",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M24.5636 23.8573V24.2141C24.5636 26.8897 17.918 34.0245 15.3943 34.0245C12.8707 34.0245 15.3943 26.8897 15.3943 24.2141V23.8573L0.84122 20.8696C0.546793 20.825 0.252366 20.7358 0 20.5574V34.0245C0 37.3244 2.52366 40 5.63617 40H34.3638C37.4763 40 40 37.3244 40 34.0245V20.5574C39.7476 20.6912 39.4532 20.7804 39.1588 20.8696L24.5636 23.8573Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M34.4059 8.47269H29.2745V6.24303C29.2745 2.80936 26.6246 0 23.3859 0H16.572C13.3333 0 10.6835 2.80936 10.6835 6.24303V8.47269H5.55205C2.4816 8.47269 0 11.1037 0 14.359V16.8562C0 17.6589 0.546793 18.3278 1.26183 18.5061L15.3943 21.4047C15.4364 18.7737 17.4553 16.6778 19.979 16.6778C22.4606 16.6778 24.5216 18.7737 24.5636 21.4047L38.6541 18.5061C39.4112 18.3278 39.9579 17.6589 39.9579 16.8562V14.3144C39.9579 11.1037 37.4763 8.47269 34.4059 8.47269ZM15.0158 6.19844C15.0158 5.30658 15.7308 4.59309 16.53 4.59309H23.3859C24.2271 4.59309 24.9001 5.35117 24.9001 6.19844V8.42809H15.0158V6.19844Z",
          fill: "#333333",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.2927 24.2141V21.5385C22.2927 20.2007 21.2412 19.0859 19.9794 19.0859C18.7175 19.0859 17.666 20.2007 17.666 21.5385V24.2141C17.666 25.5519 18.7175 26.6667 19.9794 26.6667C21.2412 26.6667 22.2927 25.5519 22.2927 24.2141Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
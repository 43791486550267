var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "25",
        viewBox: "0 0 25 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M16.573 5.36928C15.7077 5.36928 15.2221 4.38941 15.7113 3.70161L16.0454 3.12197C14.8623 2.6614 13.6106 2.43353 12.34 2.43353C6.69153 2.43353 2.11283 7.01223 2.11283 12.6603C2.11283 18.3081 6.69153 22.8865 12.34 22.8865C13.7294 22.8865 13.7294 25 12.34 25C5.52487 25 0 19.4748 0 12.6603C0 5.84557 5.52487 0.320058 12.34 0.320058C13.9749 0.320058 15.6018 0.647429 17.1096 1.27938L17.5442 0.526751C17.955 -0.188331 18.9814 -0.165864 19.374 0.536379L21.2519 3.78859C21.6582 4.49629 21.1366 5.36575 20.3397 5.36543L16.573 5.36928ZM21.3103 7.75299C20.6427 6.53658 22.4917 5.52173 23.1593 6.73782C23.5146 7.38422 23.8204 8.08325 24.0528 8.78325C24.4871 10.1027 22.4814 10.7629 22.0469 9.44377C21.8507 8.85225 21.6104 8.29925 21.3103 7.75299ZM22.5877 12.8115C22.6143 11.4273 24.7194 11.4683 24.6921 12.8529C24.6767 13.6052 24.5952 14.3306 24.4434 15.0675C24.1661 16.4248 22.1027 16.004 22.3794 14.6467C22.5023 14.0507 22.5748 13.42 22.5877 12.8115ZM21.1633 17.828C21.879 16.6456 23.6786 17.7352 22.9632 18.9176C22.5774 19.5531 22.1351 20.1581 21.6408 20.714C20.7194 21.7494 19.1422 20.3462 20.0647 19.3105C20.4742 18.8496 20.8436 18.356 21.1633 17.828ZM17.4197 21.458C18.6358 20.7901 19.6513 22.6391 18.4352 23.307C17.7817 23.6661 17.0968 23.9614 16.3894 24.196C15.0758 24.6303 14.4156 22.6327 15.7289 22.1987C16.313 22.0049 16.8808 21.7545 17.4197 21.458Z",
          fill: "#5D4EBB",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
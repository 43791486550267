var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "25",
        viewBox: "0 0 25 25",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M22.6842 1.65625H20.5263V3.76151C20.5263 4.84046 19.6579 5.7352 18.5526 5.7352H16.6579C15.5526 5.7352 14.6842 4.84046 14.6842 3.76151V1.65625H10.2632V3.76151C10.2632 4.84046 9.39474 5.7352 8.28947 5.7352H6.39474C5.28947 5.7352 4.42105 4.84046 4.42105 3.76151V1.65625H2.26316C1 1.65625 0 2.68257 0 3.91941V8.39309C0 8.68257 0.236842 8.89309 0.5 8.89309H24.4474C24.7368 8.89309 24.9474 8.65625 24.9474 8.39309V3.91941C24.9474 2.65625 23.9474 1.65625 22.6842 1.65625Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M22.7895 21.7358C22.7895 22.3411 22.2895 22.8148 21.7105 22.8148H3.26316C2.65789 22.8148 2.18421 22.3148 2.18421 21.7358V11.4727C2.18421 11.1832 1.94737 10.9727 1.68421 10.9727H0.5C0.210526 10.9727 0 11.2095 0 11.4727V21.7358C0 23.5516 1.44737 24.999 3.26316 24.999H21.6842C23.4737 24.999 24.9474 23.5516 24.9474 21.7358V11.4727C24.9474 11.1832 24.7105 10.9727 24.4474 10.9727H23.2895C23 10.9727 22.7895 11.2095 22.7895 11.4727V21.7358Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.31517 15.1314H8.28886C8.63096 15.1314 8.92044 14.8419 8.92044 14.4998V12.0261C8.92044 11.684 8.63096 11.3945 8.28886 11.3945H5.31517C4.97307 11.3945 4.68359 11.684 4.68359 12.0261V14.4998C4.70991 14.8419 4.97307 15.1314 5.31517 15.1314Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.657 15.1314H19.6307C19.9728 15.1314 20.2622 14.8419 20.2622 14.4998V12.0261C20.2622 11.684 19.9728 11.3945 19.6307 11.3945H16.657C16.3149 11.3945 16.0254 11.684 16.0254 12.0261V14.4998C16.0254 14.8419 16.2885 15.1314 16.657 15.1314Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.0254 19.6834C16.0254 20.0255 16.3149 20.315 16.657 20.315H19.6307C19.9728 20.315 20.2622 20.0255 20.2622 19.6834V17.2097C20.2622 16.8676 19.9728 16.5781 19.6307 16.5781H16.657C16.3149 16.5781 16.0254 16.8676 16.0254 17.2097V19.6834Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.9734 15.1314H13.9471C14.2892 15.1314 14.5786 14.8419 14.5786 14.4998V12.0261C14.5786 11.684 14.2892 11.3945 13.9471 11.3945H10.9734C10.6313 11.3945 10.3418 11.684 10.3418 12.0261V14.4998C10.3681 14.8419 10.6313 15.1314 10.9734 15.1314Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M4.70991 19.6834C4.70991 20.0255 4.99938 20.315 5.34149 20.315H8.31517C8.65728 20.315 8.94675 20.0255 8.94675 19.6834V17.2097C8.94675 16.8676 8.65728 16.5781 8.31517 16.5781H5.31517C4.97307 16.5781 4.68359 16.8676 4.68359 17.2097V19.6834H4.70991Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10.3681 19.6834C10.3681 20.0255 10.6576 20.315 10.9997 20.315H13.9734C14.3155 20.315 14.605 20.0255 14.605 19.6834V17.2097C14.605 16.8676 14.3155 16.5781 13.9734 16.5781H10.9734C10.6313 16.5781 10.3418 16.8676 10.3418 17.2097V19.6834H10.3681Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M6.39124 4.42105H8.28598C8.6544 4.42105 8.94387 4.13158 8.94387 3.76316V0.789474C8.94387 0.368421 8.60177 0 8.1544 0H6.4965C6.04914 0 5.70703 0.368421 5.70703 0.789474V3.73684C5.70703 4.10526 6.02282 4.42105 6.39124 4.42105Z",
          fill: "#5D4EBB",
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.6569 4.42105H18.5516C18.92 4.42105 19.2095 4.13158 19.2095 3.76316V0.789474C19.2095 0.368421 18.8674 0 18.42 0H16.7621C16.3148 0 15.9727 0.368421 15.9727 0.789474V3.73684C15.999 4.10526 16.2884 4.42105 16.6569 4.42105Z",
          fill: "#5D4EBB",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div :class="'purchaseValueProposition ' + className">
        <b-row>
            <b-col sm="6" xxl="3" class="value mb-4 mb-xxl-0">
                <div class="valueIcon"><PurchaseBed /></div>
                <div class="valueText"><p>{{totalPropertiesCount}} hotel, B&B e appartamenti in Italia e nel mondo</p></div>
            </b-col>
            <b-col sm="6" xxl="3" class="value mb-4 mb-xxl-0">
                <div class="valueIcon"><PurchaseSpin /></div>
                <div class="valueText"><p>Disponibilità in tempo reale prenotabili con pochi click</p></div>
            </b-col>
            <b-col sm="6" xxl="3" class="value mb-4 mb-xxl-0">
                <div class="valueIcon"><PurchaseCalendar /></div>
                <div class="valueText">
                    <p>
                        <CopyScadenza
                            default="Puoi prenotare e viaggiare entro {value} mesi da ora"
                            promoMonths="Puoi prenotare e viaggiare entro {value} mesi da ora"
                            promoFixed="Puoi prenotare e viaggiare entro il {value}"
                        />                        
                    </p>
                </div>
            </b-col>
            <b-col sm="6" xxl="3" class="value mb-4 mb-xxl-0">
                <div class="valueIcon"><PurchaseArrowCircle /></div>
                <div class="valueText"><p>Tantissime disponibilità rimborsabili con riattivazione automatica del voucher</p></div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import Vue from 'vue'
    import PurchaseBed from './../svg/purchase-bed'
    import PurchaseSpin from './../svg/purchase-spin'
    import PurchaseCalendar from './../svg/purchase-calendar'
    import PurchaseArrowCircle from './../svg/purchase-arrow-circle'
    import CopyScadenza from '@/components/blocks/common/copy-scadenza'

    export default {
        name: 'purchase-value-propositions',
        props:
        {
            className: {
                type: String,
                default: ''
            }
        },
        components:
        {
            PurchaseBed,
            PurchaseSpin,
            PurchaseCalendar,
            PurchaseArrowCircle,
            CopyScadenza,    
        },
        data(){
            return {
              totalPropertiesCount: Vue.prototype.$config.guiSettings.totalPropertiesCount,
            }
        },
        mounted()
        {
        }
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';

    .purchaseValueProposition
    {
        .value
        {
            display:flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: flex-start;
        }
        .valueIcon
        {
            margin-bottom: $grid-gutter-width/3;

            svg
            {
                width: 40px;
                height: 40px;
                max-width: none;
            }
        }
        .valueText
        {
            font-weight: bold;
        }
    }
</style>
<template>
<div :class="'boxset boxset-'+band">
    <div v-if="loading"><Spinner /></div>
    <div v-else class="boxsetWrapper">
    <b-row>
        <b-col class="boxsetLeftColumn" sm="12" lg="4" xl="4" xxl="3">
            <p class="text-center purchasingText">Stai acquistando il voucher:</p>
            <div class="voucherLeft">
                <img :src="boxsetBasepath+boxset.image" class="boxsetThumbnail">
            </div>

            <div class="checkoutVoucher checkoutVoucherBookinEngine" v-if="$route.name == 'checkout'"><!-- solo in checkout NON in conferma -->
                <div class="checkoutBookingEngine"><!-- solo DESKTOP -->
                    <div id="scopri-cosa-puoi-prenotare" class="panel panel-dark-blue large-radius">
                        <h6 class="white text-center mb-0">Scopri cosa puoi prenotare!</h6>
                    </div>
                    <div class="engineDesktop" id="modal-scopri-disponibilita-checkout">
                        <SearchBar
                            :loading="false"
                            :band="boxset.band"
                            :focus="boxset.focus"
                            :geo="{}"
                            :dates="{nights: bookingData.nights}"
                            :occupancy="{}"
                            :hasResults="false"
                            :datePickerSelector="'datepicker-scopri-disponibilita-'+_uid"
                            v-on:submit-search="submitSearch"
                        />
                    </div>
                </div>
            </div>

            <div v-if="mixinGetIntegrationInfo().mode == 'boxset' && mixinGetIntegrationInfo().sso == false" class="panel-dark-blue large-radius voucherInfo mb-0">
                <h3 class="voucherInfoTitle">
                    <SvgHeartDollar /><span>Acquista con il welfare aziendale!</span>
                </h3>
                <!--
                <p class="voucherInfoText">
                    Se vuoi acquistare questo voucher con il tuo credito welfare val al tuo portale welfare e cerca Salabam.
                </p>
                -->
                <span class="voucherInfoLink text-right">
                    <router-link :to="{name: 'assistenza-acquista-con-il-welfare-aziendale'}">
                        Scopri di più
                    </router-link>
                </span>
            </div>

            <b-row class="mx-0">
                <div class="col-sm-6 col-lg-12 px-0 pr-sm-3 pr-lg-0 voucherInfo checkoutVoucher checkoutVoucherGiftCard" v-if="$route.name == 'checkout'"><!-- solo in checkout NON in conferma -->
                    <div class="giftCardWrapper" v-if="this.$config.guiSettings.giftCardsEnabled"><!-- solo se le gift cards sono abilitate -->
                        <div class="panel-primary large-radius giftCardInfo" v-b-modal.modalGiftCardsInfo>
                            <img src="@/assets/images/giftcard-modal.jpg" />
                            <!--<img src="@/assets/images/giftcard-modal-natale.jpg" />-->
                            <div class="giftCardInfoText">
                                <h3 class="voucherInfoTitle text-center mb-0">
                                    Scopri e regala la nostra elegante gift card
                                </h3>
                            </div>
                        </div>
                        <ModalGiftCard />
                    </div>                
                </div>

                <div v-if="this.$config.guiSettings.boxMarketing.show" class="col-sm-6 col-lg-12 my-lg-0 panel-primary large-radius voucherInfo">
                    <h3 class="voucherInfoTitle">
                        <SvgPromoAdvice /><span>{{this.$config.guiSettings.boxMarketing.title}}</span>
                    </h3>
                    <p class="voucherInfoText" v-html="this.$config.guiSettings.boxMarketing.text"></p>
                    <span class="voucherInfoLink text-right" v-if="this.$config.guiSettings.boxMarketing.linkHref">
                    <a :href="this.$config.guiSettings.boxMarketing.linkHref" :target="this.$config.guiSettings.boxMarketing.linkTarget">{{this.$config.guiSettings.boxMarketing.linkA}}</a>
                    </span>
                </div>
            </b-row>

        </b-col>
        <b-col class="boxsetRightColumn mt-sm-5 mt-lg-0" sm="12" lg="8" xl="8" xxl="9">
            <div v-if="boxsetId == 0">
                <h1 class="black">Complimenti!</h1>
                <p class=""><strong>Hai appena acquistato:</strong></p>
            </div>
            <BoxsetResume
                :focus="focus"
                :band="band"
                :nights="nights"
                :price="public_price"
                :expireDate="expireDate"
                :bookingHidePrice="$config.guiSettings.bookingHidePrice"
            />

            <!-- solo in checkout MOBILE, NON in conferma -->
            <div v-if="$route.name == 'checkout'" class="checkoutBookingEngine">
                <b-button class="showBookingEngineButtonMobile btn-block mt-2 mb-4" v-b-modal.modal-scopri-disponibilita-checkout>Scopri le disponibilità</b-button>
                <b-modal id="modal-scopri-disponibilita-checkout" size="md" :title="'Scopri le disponibilità'">
                    <div class="modalBookingEngineCheckout">
                        <SearchBar
                            :loading="false"
                            :band="boxset.band"
                            :focus="boxset.focus"
                            :geo="{}"
                            :dates="{nights: bookingData.nights}"
                            :occupancy="{}"
                            :hasResults="false"
                            :datePickerSelector="'datepicker-scopri-disponibilita-'+_uid"
                            v-on:submit-search="submitSearch"
                        />
                    </div>
                </b-modal>
            </div>
            <!-- solo in checkout MOBILE, NON in conferma -->

            <div class="panel-dark-blue voucherAlert" v-if="this.$route.name != 'confirm-boxset'">
                <div class="top">
                    <SvgExclamation />
                    <div>
                        <CopyScadenza />
                    </div>
                </div>
            </div>

            <!--checkout-->
            <div v-if="boxsetId !== 0" class="infoBoxsetPurchaseWrapper">
                <p>
                    Acquistando questo voucher potrai prenotare un soggiorno di {{nightsText}} in una qualsiasi località {{localitaText}} di tua preferenza.
                    <CopyScadenza
                        default="Il Salabam è utilizzabile entro {value} mesi dalla data di acquisto, potrai quindi acquistare ora e scegliere con calma la destinazione la struttura in cui effettuare il soggiorno."
                        promoMonths="Il Salabam è utilizzabile entro {value} mesi dalla data di acquisto, potrai quindi acquistare ora e scegliere con calma la destinazione la struttura in cui effettuare il soggiorno."
                        promoFixed="Il Salabam è utilizzabile entro il {value}, potrai quindi acquistare ora e scegliere con calma la destinazione la struttura in cui effettuare il soggiorno."
                        extra="Ti ricordiamo che la data di scadenza indica l'ultima data possibile selezionabile come check-in."
                    />                    
                </p>

                <div v-if="mixinGetIntegrationInfo().sso" class="infoBoxsetPurchase">
                    <b-alert show variant="info">
                        Acquista ora e riceverai dopo pochi istanti un'email con le istruzioni ed il link per effettuare la prenotazione del soggiorno.    
                    </b-alert>                    
                </div>

            </div>

            <!--confirm-boxset-->
            <div v-else>

                <!-- non mostro il messaggio che abbiamo inviato la mail (all'indirizzo del voucher gift@salabam.com) se è una giftcard-->
                <div v-if="boxset.email != 'gift@salabam.com'" class="infoBoxsetPurchase mt-4">
                    <b-alert show variant="success">
                        Abbiamo appena inviato all'email <strong class="secondary">{{boxset.email}}</strong> la conferma d'acquisto, il codice del voucher e le istruzioni per effettuare la prenotazione del viaggio.
                    </b-alert>
                </div>

                <div class="panel panel-gray text-center">

                    <br>

                    <span class="smallText">Se non dovessi ricevere l'email controlla anche la cartella spam o contatta l'assistenza.</span>

                    <br><br><br>

                    <b-row class="confirmButtons">
                      
                      <b-col class="mb-5 mb-md-0" sd="12" md="6">
                        <p class="text-center">
                          <strong>Stampa il tuo voucher:</strong>
                        </p>
                        <b-button class="btn-block" @click="print()">Stampa!</b-button>
                      </b-col>
                      
                      <b-col sd="12" md="6">
                        <p class="text-center">
                          <strong>Oppure usa subito il tuo voucher:</strong>
                        </p>
                        <p class="text-center">
                          <b-button class="btn-block" @click="logoutAndRedirect">Prenota ora!</b-button>
                        </p>
                      </b-col>
                    
                    </b-row>

                    <p class="text-center" v-if="redirect">
                        <br><br>
                        <strong>Vuoi acquistare un altro voucher Salabam?</strong>
                        <br>
                        <span class="smallText">
                            Per effettuare un altro acquisto devi per prima cosa fare un nuovo login sul tuo portale welfare e 
                            scegliere ancora Salabam tornando così su questo sito per il nuovo acquisto
                        </span>
                    </p>

                    <br>

                </div>
            </div>

            <div class="purchaseValuePropositionsCheckoutWrapper">
                <PurchaseValuePropositions :className="'boxset-'+band" />
            </div>

            <div class="termsShortCheckoutWrapper">
                <TermsShort />
            </div>

            <div class="valuePropositionsCheckoutWrapper">
                <div class="panel panel-gray">
                    <ValuePropositions />
                </div>
                <br>
            </div>

        </b-col>        
    </b-row>
    </div>
</div>
</template>

<script>
import Vue from 'vue'
import Spinner from './../../atoms/spinner'
//import BoxsetIcon from './../../svg/boxset-icon'
import BoxsetResume from './boxset-resume'
import SearchBar from '@/components/blocks/search-bar'
import PurchaseValuePropositions from './../purchase-value-propositions'
import ValuePropositions from './../../blocks/footer/value-propositions'
import { mapGetters } from 'vuex'
import TermsShort from '@/components/blocks/terms-short'
import ModalGiftCard from '@/components/blocks/common/modal-gift-card'
import CopyScadenza from '@/components/blocks/common/copy-scadenza'
import SvgExclamation from '@/components/svg/exclamation'
import SvgPromoAdvice from '@/components/svg/promo-advice'
import SvgHeartDollar from '@/components/svg/heart-dollar'

export default {
    name: 'boxset',
    components:
    {
      TermsShort,
      ModalGiftCard,
      CopyScadenza,
      Spinner, BoxsetResume, SearchBar,//BoxsetIcon,
      PurchaseValuePropositions,
      ValuePropositions,
      SvgExclamation,
      SvgPromoAdvice,
      SvgHeartDollar,
    },
    props:
    {   
        //usato al checkout, in questo caso prende i dati da bookingData
        boxsetId: {
            type: Number,
            default: 0,
        },
        //usato alla conferma, in questo caso prende i dati da boxset
        boxsetObject:
        {
            type: Object,
            default: function() { return {}}
        }
    },
    computed:{
      ...mapGetters({
        bookingData:   'getBookingData',
      }),
      nightsText() {
        return (this.nights > 1)? this.nights + ' notti' : this.nights + ' notte'
      },
      localitaText(){
        return this.localitaDaFocus(this.focus)
      }
    },
    data() {
      return {
        loading: true,
        boxset: {},
        boxsetBasepath: this.$config.boxsets_images_basepath,
        focus : '',
        band : '',
        nights : 0,
        expireDate : new Date(this.$config.guiSettings.vouchersValidity.newDateExpireAt),
        public_price: 0,
        redirect: false,
        config: Vue.prototype.$config,
      }
    },
    mounted()
    {
        /*
        if(process.env.VUE_APP_MODE != 'production'){
          window.console.log('vouchersValidity.newDateExpireAt = ' + this.$config.guiSettings.vouchersValidity.newDateExpireAt)
          window.console.log(new Date(this.$config.guiSettings.vouchersValidity.newDateExpireAt))
        }
        */

        this.loading = true
        this.boxset = this.getBoxset(this.boxsetId)
        if(this.boxsetId !== 0)
        {
            this.focus = this.$store.state.focus
            this.band = this.$store.state.activeBand
            this.nights = this.$store.state.dates.nights
            //this.expireDate = new Date((new Date()).getFullYear() + 1, (new Date()).getMonth(), (new Date()).getDate())
            this.public_price = this.boxset.public_price
        }
        else //caso upselling, cofanetto custom con boxsetId == 0
        {
            this.focus = this.boxset.focus
            this.band = this.boxset.band
            this.nights = parseInt(this.boxset.nights)
            //this.expireDate = new Date(this.boxset.dataScadenza)
            this.public_price = parseFloat(this.boxset.public_price)
        }

        if(this.config.integration !== undefined && this.config.integration.orderConfirmedRedirectTo !== '')
        {
            this.redirect = true
        }

        this.loading = false
    },
    methods:
    {
        logoutAndRedirect()
        {
            this.$parent.logoutAndRedirect(this.boxset.confirmcode,this.boxset.email)
        },
        print: function(){
          // ps. se per mila motivi la pagina sta ancora caricando, non stampa
          if(process.env.VUE_APP_MODE != 'production') window.console.log('print!')
          window.print()
        },
        getBoxset(boxset_id)
        {
            if(boxset_id == 0)
            {
                return this.boxsetObject
            }

            return this.mixinGetBoxsetFromConfig(boxset_id).boxset
        },
        submitSearch()
        {
            this.mixinSendMutation('setTriggerSearch',true)
            this.mixinGoTo('search',{})
        },

        localitaDaFocus: function(focus){
          // metodo duplicato a mano su checkout.vue e boxset.vue
          switch(focus){

            case 'italia':
              return 'italia';

            case 'europa':
            case 'ita-eu':
              return 'europea';

            case 'mondo':
              return 'extra-europea';

            case '':
            case 'all':
            default:
              return 'qualsiasi';
          }

        } // localitaDaFocus,
    }
}
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../../scss/_custom.scss';

    #modal-scopri-disponibilita-checkout
    {
        @include media-breakpoint-up(lg)
        {
            background:$light-gray;
            border-radius: $border-radius-large;
            margin-top:$grid-gutter-width/3;
        }
        footer
        {
            display:none;
        }
        #searchBar
        {
            padding:$grid-gutter-width/3;

            .userBox,
            .breadcrumbs,
            #search-form-filters
            {
                display:none !important;
            }
            #search-form
            {
                padding:0;
                background:transparent;
            }
            form
            {
                flex-direction:column;
                font-size: 13px;
                input,select,.dropdown-toggle //,.btn
                {
                    font-size: 13px;
                }
            }
            .search-form-item
            {
                flex:1;
                width:100%;
                margin:0 0 0 0;
                padding:0 0 0 0;
            &:not(#search-form-buttons)
                {
                    margin-bottom:1rem;
                }
            }
        }
    }

    .boxset
    {
        @include media-breakpoint-up(md)
        {
            .showBookingEngineButtonMobile
            {
                display:none;
            }
        }
        .boxsetWrapper
        {
            display:block;
            width:100%;
        }
        .smallText
        {
            font-size: 14px;
        }
        ol>li{
        list-style-type: disc;
        margin-bottom: 15px;
        }
        .boxsetResumeMessage
        {
            width:100%;
        }
        .primaryBackground
        {
            background:$primary;
        }
        .voucherLeft
        {
            position:relative;
            margin-bottom: 30px;

            img {
                width: 200px;
                display: block;
                margin: 0 auto;
            }
        }
        .purchaseValuePropositionsCheckoutWrapper {
            margin: 40px 0 50px;
        }
        .valuePropositionsCheckoutWrapper {
            margin-top: $grid-gutter-width;
        }
        .salaBadges
        {
            position:absolute;
            width:100%;
            height:auto;
            max-height:100%;
            bottom:0;
            left:0;
            padding:0.75rem;
        }

        // value propositions
        .prefooter-blocks-large .block-wrap {

            svg
            {
                margin-bottom: 1rem;
                transform:scale(0.8);
            }
            strong
            {
                font-size: 16px;   
            }
            p
            {
                font-size: 14px;
            }
        }

        .infoBoxsetPurchase
        {
            text-align:center;
            margin:0 auto;
            margin-top:3rem;
            margin-bottom:3rem;
            max-width: 660px;
            .alert
            {
                padding-top:1.25rem;
                padding-bottom:1.25rem;
            }
            p
            {
                margin-bottom:0;
            }
            .infoBoxsetPurchaseCopy
            {
                //font-size: 18px;
                font-weight: normal;
                color:$black;
                @include media-breakpoint-up(md)
                {
                    //font-size: 22px;
                    //line-height: 160%;
                    //padding:0 1rem;
                }
            }
        }

        .voucherAlert
        {
            margin-top:$grid-gutter-width/2;
            font-size: 14px;
            padding:1rem 1.5rem;
            .top
            {
                display:flex;
                align-items: center;
            }
            svg
            {
                width:30px;
                min-width:30px;
                margin-right:16px;
            }   
            .voucherAlertLink
            {
                min-width: 100%;
                width: 100%;
                display:block;
                text-align: right;
                text-transform: uppercase;
                font-weight: bold;
                margin-top:1rem;
                a
                {
                    text-decoration: underline;
                    color:$white;
                    &:hover
                    {
                        color:$white;
                    }
                }
            }
        }

        .confirmButtons
        {
            max-width:700px;
            margin:auto;
        }

        .voucherInfo
        {
            margin-top:$grid-gutter-width/2;
            font-size: 14px;

            @at-root .confirm-boxset & {
                flex: 0 0 100%;
                max-width: 100%;
            }

            .voucherInfoTitle
            {
                color:$white;
                font-size: 0.95rem;
                display:flex;
                align-items:center;

                svg
                {
                    width:30px;
                    min-width:30px;
                    margin-right:10px;
                }

                @include media-breakpoint-down(lg){
                    justify-content: center;
                }
            }
            .voucherInfoText
            {
                color:$white;
            }
            .voucherInfoLink
            {
                display:block;
                text-align: left;
                text-transform: uppercase;
                font-weight: bold;
                a
                {
                    text-decoration: underline;
                    color:$white;
                    font-size: 11px;
                    display: block;
                    text-align: center;

                    &:hover
                    {
                        color:$white;
                    }
                }
            }
        }

        .giftCardInfo
        {
            padding:0;
            color:$white;
            cursor:pointer;
            overflow: hidden;
            .giftCardInfoText
            {
                padding:$grid-gutter-width/4;
            }
            p
            {
                text-align:center;
                font-size: 16px;
                margin-bottom:0;
            }
        }

      //refines ipad
      @include media-breakpoint-only(lg)
      {
        .boxsetWrapper .col-lg-4,
        .boxsetWrapper .col-lg-8
        {
            padding-right:0;
        }
      }
    }

    //modal checkout mobile
    @include media-breakpoint-down(xs)
    {
        .giftCardWrapper
        {
            display:none !important;
        }

        .modalBookingEngineCheckout
        {
            #search-form-datepicker,
            #search-form-nights,
            #search-form-occupancy,
            #search-form-buttons
            {
                display:block;
            }


            #toggle-search-settings
            {
                display:none !important;
            }
        }

        #scopri-cosa-puoi-prenotare,
        #modal-scopri-disponibilita-checkout
        {
            display:none;
        }
        
        .boxsetLeftColumn
        {
            order:2;
            margin:2rem 0;
            .purchasingText
            {
                display:none;
            }
            #modal-scopri-disponibilita-checkout
            {
                #searchBar
                {
                    background: $light-gray;
                }
            }
        }

    }

    // mostrato solo in checkout e non in conferma
    .checkoutVoucherBookinEngine
    {
        .panel-dark-blue
        {
            *
            {
                font-size: 0.95rem;
            }
            padding:1.2rem 1rem;
            margin-top:1rem;
            margin-bottom:0;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        .engineDesktop
        {
            margin-top:0 !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "checkout" } },
    [
      _c("Breadcrumbs", { attrs: { activeStep: 3 } }),
      _vm.canceling
        ? _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c(
                "b-col",
                [
                  _vm._v(
                    "\n      Attendi un attimo, stiamo cancellando la prenotazione.\n      "
                  ),
                  _c("br"),
                  _c("br"),
                  _c("b", [_vm._v("Non lasciare questa pagina.")]),
                  _c("br"),
                  _c("br"),
                  _c("Spinner"),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.cancelMessage !== ""
        ? _c(
            "b-row",
            { staticStyle: { flex: "1" } },
            [
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "b-alert",
                      {
                        staticClass: "d-inline",
                        attrs: { variant: "success", show: "" },
                      },
                      [_vm._v(_vm._s(_vm.cancelMessage))]
                    ),
                    _c("br"),
                    Object.keys(_vm.refundDiscountCode).length
                      ? _c("p", [
                          _vm._v("\n        Abbiamo emesso il buono sconto "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.refundDiscountCode.code)),
                          ]),
                          _vm._v(" del valore di "),
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.refundDiscountCode.valueNominal) + "€"
                            ),
                          ]),
                          _vm._v(" da utilizzarsi entro il "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.refundDiscountCode.expireAt)),
                          ]),
                          _vm._v(" (gg-mm-aa) per una nuova prenotazione."),
                          _c("br"),
                          _c("br"),
                          _vm._v(
                            '\n        Questo buono ti è stato inviato anche per email insieme alla "conferma di cancellazione" di questa prenotazione.'
                          ),
                          _c("br"),
                          _c("br"),
                          _c("br"),
                          _c(
                            "a",
                            { attrs: { href: _vm.refundDiscountCode.link } },
                            [
                              _c("b-button", { attrs: { variant: "" } }, [
                                _vm._v("Usa il buono adesso"),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("br"),
                    _c("br"),
                    _c("br"),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm.salabam !== undefined
        ? _c(
            "b-container",
            { attrs: { fluid: "", id: "main" } },
            [
              _vm.loading ? _c("Spinner") : _vm._e(),
              _c(
                "b-row",
                { staticStyle: { flex: "1" } },
                [
                  _c("b-col", { attrs: { sm: "12", md: "8", xxl: "9" } }, [
                    _c(
                      "div",
                      { attrs: { id: "content" } },
                      [
                        !_vm.loading &&
                        !_vm.canceling &&
                        _vm.cancelMessage == ""
                          ? _c("Reservation", {
                              attrs: {
                                bookingData: _vm.bookingData,
                                guest: _vm.guest,
                                confirmcode: "",
                                itinerary_id: "",
                                reservation: _vm.reservation,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  !_vm.canceling
                    ? _c("b-col", { attrs: { sm: "12", md: "4", xxl: "3" } }, [
                        _c("div", { staticClass: "confirmSidebar" }, [
                          !_vm.loading
                            ? _c("div", [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "confirmSidebarTitle text-left",
                                  },
                                  [
                                    _vm._v(
                                      "Per fare questa prenotazione è stato utilizzato il:"
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _c(
                                  "div",
                                  { staticClass: "panel-white" },
                                  [
                                    _c("BoxsetResume", {
                                      attrs: {
                                        focus: _vm.salabam.focus,
                                        band: _vm.salabam.band,
                                        nights: _vm.salabam.nights,
                                        price: parseFloat(
                                          _vm.salabam.public_price
                                        ),
                                        bookingHidePrice:
                                          this.$config.guiSettings
                                            .bookingHidePrice,
                                      },
                                    }),
                                    _c("p", { staticClass: "mt-4" }, [
                                      _vm._v(
                                        "\n                    Titolare: " +
                                          _vm._s(_vm.salabam.beneficiario) +
                                          "\n                    "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                    Codice Salabam: " +
                                          _vm._s(_vm.salabam.confirmcode) +
                                          "\n                    "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                    Valido fino al: " +
                                          _vm._s(_vm.salabam.expireAt) +
                                          "\n                    "
                                      ),
                                      _c("br"),
                                    ]),
                                    _c("p", { staticClass: "small" }, [
                                      _vm._v(
                                        "\n                    Ove possibile in base alle policy di cancellazione e in caso di cancellazione di questa prenotazione, il voucher Salabam verrà riattivato e sarà valido fino a questa data (il check-in dovrà essere fatto entro questa data\n                  "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          !_vm.loading && Object.keys(_vm.upselling).length > 0
                            ? _c("div", [
                                _c("div", { staticClass: "panel-white" }, [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "confirmSidebarTitle text-left",
                                    },
                                    [_vm._v("Servizi aggiunti:")]
                                  ),
                                  _c("p", { staticClass: "small" }, [
                                    _vm._v(
                                      "\n                    Per questa prenotazione sono stati richiesti e saldati tramite carta di credito dei servizi aggiuntivi\n                  "
                                    ),
                                  ]),
                                  _c("p", { staticClass: "mt-4" }, [
                                    _vm._v(
                                      "\n                    Importo pagato: " +
                                        _vm._s(_vm.upselling.amount) +
                                        " €\n                    "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                    Nome sulla carta: " +
                                        _vm._s(_vm.upselling.buyerNameSurname) +
                                        "\n                    "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                    Voce in estrattoconto: " +
                                        _vm._s(
                                          _vm.upselling.statementDescriptor
                                        ) +
                                        "\n                    "
                                    ),
                                    _c("br"),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          !_vm.loading
                            ? _c("div", { staticClass: "deleteReservation" }, [
                                _c("div", { staticClass: "panel-white" }, [
                                  _vm.canceling
                                    ? _c(
                                        "div",
                                        [
                                          _vm._v(
                                            "\n                    Attendi un attimo, stiamo cancellando la prenotazione.\n                    "
                                          ),
                                          _c("br"),
                                          _c("br"),
                                          _c("b", [
                                            _vm._v(
                                              "Non lasciare questa pagina."
                                            ),
                                          ]),
                                          _c("br"),
                                          _c("br"),
                                          _c("Spinner"),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.canceling
                                    ? _c("div", [
                                        _vm.cancelMessage !== ""
                                          ? _c("p", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(_vm.cancelMessage) +
                                                  "\n                    "
                                              ),
                                            ])
                                          : _c("div", [
                                              _c(
                                                "h5",
                                                {
                                                  staticClass:
                                                    "confirmSidebarTitle text-center",
                                                },
                                                [
                                                  _vm._v(
                                                    "Policy di cancellazione"
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _vm.reservation.refundable
                                                ? _c("div", [
                                                    _vm.refundableBeforeDate <
                                                    Date.now()
                                                      ? _c("div", [
                                                          _c("p", [
                                                            _vm._v(
                                                              "Questa prenotazione poteva essere modificata entro il "
                                                            ),
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .reservation
                                                                    .refundableBefore
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(
                                                              " (gg-mm-aaaa hh:mm:ss)"
                                                            ),
                                                          ]),
                                                        ])
                                                      : _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "text-left",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            Se lo desideri è possibile cancellare gratuitamente questa prenotazione entro il: "
                                                                ),
                                                                _c("b", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .reservation
                                                                        .refundableBefore
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm._v(
                                                                  " (GG-MM-AAAA).\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                            Object.keys(
                                                              _vm.upselling
                                                            ).length == 0
                                                              ? _c(
                                                                  "div",
                                                                  [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-left",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              Se lo farai, verrà riattivato il voucher Salabam originario indicato in questa pagina con lo stesso valore e la stessa data di scadenza (il viaggio deve essere effettuato entro la data di validità).\n                            "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "b-modal",
                                                                              rawName:
                                                                                "v-b-modal.sidebar-modal-cancel",
                                                                              modifiers:
                                                                                {
                                                                                  "sidebar-modal-cancel": true,
                                                                                },
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          variant:
                                                                            "danger",
                                                                          size: "sm",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Cancella la prenotazione"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c("div", [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "text-center",
                                                                    },
                                                                    [
                                                                      _c("br"),
                                                                      _c("i", [
                                                                        _vm._v(
                                                                          "Essendo stati acquistati dei servizi aggiuntivi, per cancellare questa prenotazione dovrai contattare l'assistenza."
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  ),
                                                                ]),
                                                            _c(
                                                              "b-modal",
                                                              {
                                                                attrs: {
                                                                  id: "sidebar-modal-cancel",
                                                                  title:
                                                                    "Cancella la prenotazione",
                                                                  "hide-footer":
                                                                    "",
                                                                  size: "lg",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mt-4 mb-4 ml-2 mr-2 text-center",
                                                                  },
                                                                  [
                                                                    !_vm.loading
                                                                      ? _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              Cancellando la prenotazione il tuo codice Salabam verrà subito riattivato e potrai effettuare una nuova prenotazione.\n                              "
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  "Sei sicuro di voler cancellare questa prenotazione?"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _c(
                                                                              "b-button",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    variant:
                                                                                      "danger",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.cancelReservation,
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Si, cancella la prenotazione"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              Attendi un attimo, stiamo cancellando la prenotazione.\n                              "
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  "Non lasciare questa pagina."
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _c(
                                                                              "Spinner"
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ])
                                                : _c("div", [
                                                    _c("p", [
                                                      _vm._v(
                                                        "Questa prenotazione non è cancellabile nè modificabile nè rimborsabile."
                                                      ),
                                                    ]),
                                                  ]),
                                            ]),
                                      ])
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "panel-gray" },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "confirmSidebarTitle text-left",
                                      },
                                      [
                                        _vm._v(
                                          "Desideri acquistare un altro voucher Salabam?"
                                        ),
                                      ]
                                    ),
                                    _c("br"),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { size: "sm" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.mixinGoTo("home", {})
                                          },
                                        },
                                      },
                                      [_vm._v("Torna alla Home")]
                                    ),
                                    _c("br"),
                                    _c("br"),
                                    _c("div", { staticClass: "d-flex" }, [
                                      _c(
                                        "div",
                                        { staticClass: "svgHands" },
                                        [_c("SvgHands")],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "small text-left ml-3" },
                                        [
                                          _vm._v(
                                            "\n                      Per acquistare un altro voucher Salabam con il tuo credito di welfare aziendale, torna sul tuo portale welfare e segui il procedimento indicato.\n                    "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
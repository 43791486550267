var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "27",
        height: "21",
        viewBox: "0 0 27 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.59998 11.4H22.9L15.9 18.4C15.5 18.8 15.5 19.4 15.9 19.8C16.3 20.2 16.9 20.2 17.3 19.8L26 11.1C26.1 11 26.2 10.9 26.2 10.8C26.3 10.6 26.3 10.3 26.2 10C26.1 9.9 26.1 9.79995 26 9.69995L17.3 1C17.1 0.8 16.8 0.699951 16.6 0.699951C16.4 0.699951 16.1 0.8 15.9 1C15.5 1.4 15.5 2.00002 15.9 2.40002L22.9 9.40002H1.59998C0.999976 9.40002 0.599976 9.80002 0.599976 10.4C0.599976 11 0.999976 11.4 1.59998 11.4Z",
          fill: "#333333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
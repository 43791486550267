<template>
  <div class="termsShortContainer">
    <h2 class="common-title mt-0 mb-4">Le principali condizioni d'uso</h2>

    <div class="termsShortModals"><!--mobile-->
      <div v-for="(term,index) in terms" v-bind:key="index">
        
        <div class="termButton">
          <SvgInfoCircle />
          <span class="termTitle" v-b-modal="'modalTerms'+index">{{term.title}}</span>
        </div>

        <b-modal :id="'modalTerms'+index" size="lg" :title="'Condizioni d\'uso'" hide-footer>
          <b-container fluid class="termsModal">
            <h4 class="termTitleModal">{{term.title}}</h4>
            <p class="termContent" v-html="term.content"></p>
          </b-container>
        </b-modal>
      </div>
    </div>

    <div class="termsShortExpanded"><!--tablets / desktop-->
      <p v-for="(term,index) in terms" v-bind:key="index">
        <span class="termTitle">{{term.title}}</span>
        <span class="termContent" v-html="term.content"></span>
      </p>
    </div>

    <p>
      Leggi tutti i <router-link :to="{name: 'terms'}" target="_blank">Termini e Condizioni</router-link>
    </p>
  </div>
</template>

<script>
  import Vue from 'vue'
  import SvgInfoCircle from '@/components/svg/info-circle'

  export default {
    name: 'terms-short',
    components: {
      SvgInfoCircle
    },
    data(){
      return {
        terms:
        [
          {
            title: 'validità',
            content: 'Il voucher è valido ' + Vue.prototype.$config.guiSettings.vouchersValidity.defaultMonths + ' mesi dalla data di acquisto'+(Vue.prototype.$config.guiSettings.vouchersValidity.isPromo ? '<br>'+Vue.prototype.$config.guiSettings.vouchersValidity.promoText : '.') + ' La data di scadenza indica l\'ultima data possibile selezionabile come check-in.'
          },
          {
            title: 'CANCELLAZIONE DELL\'ACQUISTO DEL SALABAM',
            content: 'Una volta acquistato il voucher Salabam non può essere rimborsato. Alcuni portali welfare permettono l\'effettuazione della cancellazione entro 24 ore dall\'acquisto a patto che il voucher non sia già stato utilizzato. Potrai contattare direttamente il tuo portale welfare per verificare la possibilità di cancellazione.'
          },
          {
            title: 'CANCELLAZIONE DELLA PRENOTAZIONE',
            content: 'Su Salabam trovi sia disponibilità rimborsabili sia disponibilità non-rimborsabili. Se cancelli una prenotazione rimborsabile il tuo voucher Salabam verrà automaticamente riattivato e sarà utilizzabile per una nuova prenotazione ma attenzione: la data di scadenza della validità non cambia e resta quella originaria. Le prenotazioni non-rimborsabili non danno diritto alla riattivazione del voucher.'
          },
          {
            title: 'QUANTE PERSONE POSSONO VIAGGIARE',
            content: 'Sei tu a decidere il numero di partecipanti specificandolo al momento della ricerca. Considera che essendo il valore del voucher fisso se il numero di partecipanti è alto (oltre 3 adulti) il numero di soluzioni disponibili potrebbe essere più basso.'
          },
          {
            title: 'prenotare più camere',
            content: 'Non è al momento possibile prenotare più camere con lo stesso voucher.'
          },
          {
            title: 'PRENOTARE PER IL GIORNO DOPO',
            content: 'Prenotare un soggiorno per il giorno dopo è permesso solo fino alle ore 14:00.'
          }     
        ]
      }
    }
  }
</script>

<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/mixins";
  @import './../../scss/_custom.scss';
  .termsShortContainer
  {
    .termTitle
    {
      //font-weight: bold;
      text-transform: uppercase;
      display:block;
      clear:both;
      margin-bottom:4px;
    }
    .termContent
    {
      display:block;
      margin-bottom:1.5rem;
    }    
    .termsShortModals
    {
      margin-bottom: 2rem;
      .termButton
      {
        display:flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom:10px;
        svg
        {
          width:16px;
          height:auto;
          transform:translateY(2px);
        }
      }
      .termTitle
      {
        display:block;
        width:100%;
        font-size:12px;
        padding-left:6px;
      }
    }

    .termsShortExpanded
    {
      display:none;
    }

    @include media-breakpoint-up(sm)
    {
      .termsShortModals
      {
        display:none;
      }
      .termsShortExpanded
      {
        display:block;
      }
    }

  }

  .termsModal
  {
    padding-top:1rem;
    padding-bottom:1rem;
    .termTitleModal
    {
      text-transform: uppercase;
      color:$secondary;
      font-size: 16px;
    }
    .termContent
    {
      font-size: 13px;
    }
  }
</style>
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: "boxset boxset-" + _vm.band }, [
    _vm.loading
      ? _c("div", [_c("Spinner")], 1)
      : _c(
          "div",
          { staticClass: "boxsetWrapper" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass: "boxsetLeftColumn",
                    attrs: { sm: "12", lg: "4", xl: "4", xxl: "3" },
                  },
                  [
                    _c("p", { staticClass: "text-center purchasingText" }, [
                      _vm._v("Stai acquistando il voucher:"),
                    ]),
                    _c("div", { staticClass: "voucherLeft" }, [
                      _c("img", {
                        staticClass: "boxsetThumbnail",
                        attrs: { src: _vm.boxsetBasepath + _vm.boxset.image },
                      }),
                    ]),
                    _vm.$route.name == "checkout"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "checkoutVoucher checkoutVoucherBookinEngine",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "checkoutBookingEngine" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "panel panel-dark-blue large-radius",
                                    attrs: { id: "scopri-cosa-puoi-prenotare" },
                                  },
                                  [
                                    _c(
                                      "h6",
                                      { staticClass: "white text-center mb-0" },
                                      [_vm._v("Scopri cosa puoi prenotare!")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "engineDesktop",
                                    attrs: {
                                      id: "modal-scopri-disponibilita-checkout",
                                    },
                                  },
                                  [
                                    _c("SearchBar", {
                                      attrs: {
                                        loading: false,
                                        band: _vm.boxset.band,
                                        focus: _vm.boxset.focus,
                                        geo: {},
                                        dates: {
                                          nights: _vm.bookingData.nights,
                                        },
                                        occupancy: {},
                                        hasResults: false,
                                        datePickerSelector:
                                          "datepicker-scopri-disponibilita-" +
                                          _vm._uid,
                                      },
                                      on: { "submit-search": _vm.submitSearch },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.mixinGetIntegrationInfo().mode == "boxset" &&
                    _vm.mixinGetIntegrationInfo().sso == false
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "panel-dark-blue large-radius voucherInfo mb-0",
                          },
                          [
                            _c(
                              "h3",
                              { staticClass: "voucherInfoTitle" },
                              [
                                _c("SvgHeartDollar"),
                                _c("span", [
                                  _vm._v("Acquista con il welfare aziendale!"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "span",
                              { staticClass: "voucherInfoLink text-right" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "assistenza-acquista-con-il-welfare-aziendale",
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        Scopri di più\n                    "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("b-row", { staticClass: "mx-0" }, [
                      _vm.$route.name == "checkout"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-6 col-lg-12 px-0 pr-sm-3 pr-lg-0 voucherInfo checkoutVoucher checkoutVoucherGiftCard",
                            },
                            [
                              this.$config.guiSettings.giftCardsEnabled
                                ? _c(
                                    "div",
                                    { staticClass: "giftCardWrapper" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName:
                                                "v-b-modal.modalGiftCardsInfo",
                                              modifiers: {
                                                modalGiftCardsInfo: true,
                                              },
                                            },
                                          ],
                                          staticClass:
                                            "panel-primary large-radius giftCardInfo",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: require("@/assets/images/giftcard-modal.jpg"),
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "giftCardInfoText" },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "voucherInfoTitle text-center mb-0",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    Scopri e regala la nostra elegante gift card\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("ModalGiftCard"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      this.$config.guiSettings.boxMarketing.show
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-6 col-lg-12 my-lg-0 panel-primary large-radius voucherInfo",
                            },
                            [
                              _c(
                                "h3",
                                { staticClass: "voucherInfoTitle" },
                                [
                                  _c("SvgPromoAdvice"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        this.$config.guiSettings.boxMarketing
                                          .title
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("p", {
                                staticClass: "voucherInfoText",
                                domProps: {
                                  innerHTML: _vm._s(
                                    this.$config.guiSettings.boxMarketing.text
                                  ),
                                },
                              }),
                              this.$config.guiSettings.boxMarketing.linkHref
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "voucherInfoLink text-right",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: this.$config.guiSettings
                                              .boxMarketing.linkHref,
                                            target:
                                              this.$config.guiSettings
                                                .boxMarketing.linkTarget,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              this.$config.guiSettings
                                                .boxMarketing.linkA
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass: "boxsetRightColumn mt-sm-5 mt-lg-0",
                    attrs: { sm: "12", lg: "8", xl: "8", xxl: "9" },
                  },
                  [
                    _vm.boxsetId == 0
                      ? _c("div", [
                          _c("h1", { staticClass: "black" }, [
                            _vm._v("Complimenti!"),
                          ]),
                          _c("p", {}, [
                            _c("strong", [_vm._v("Hai appena acquistato:")]),
                          ]),
                        ])
                      : _vm._e(),
                    _c("BoxsetResume", {
                      attrs: {
                        focus: _vm.focus,
                        band: _vm.band,
                        nights: _vm.nights,
                        price: _vm.public_price,
                        expireDate: _vm.expireDate,
                        bookingHidePrice:
                          _vm.$config.guiSettings.bookingHidePrice,
                      },
                    }),
                    _vm.$route.name == "checkout"
                      ? _c(
                          "div",
                          { staticClass: "checkoutBookingEngine" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-modal",
                                    rawName:
                                      "v-b-modal.modal-scopri-disponibilita-checkout",
                                    modifiers: {
                                      "modal-scopri-disponibilita-checkout": true,
                                    },
                                  },
                                ],
                                staticClass:
                                  "showBookingEngineButtonMobile btn-block mt-2 mb-4",
                              },
                              [_vm._v("Scopri le disponibilità")]
                            ),
                            _c(
                              "b-modal",
                              {
                                attrs: {
                                  id: "modal-scopri-disponibilita-checkout",
                                  size: "md",
                                  title: "Scopri le disponibilità",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "modalBookingEngineCheckout" },
                                  [
                                    _c("SearchBar", {
                                      attrs: {
                                        loading: false,
                                        band: _vm.boxset.band,
                                        focus: _vm.boxset.focus,
                                        geo: {},
                                        dates: {
                                          nights: _vm.bookingData.nights,
                                        },
                                        occupancy: {},
                                        hasResults: false,
                                        datePickerSelector:
                                          "datepicker-scopri-disponibilita-" +
                                          _vm._uid,
                                      },
                                      on: { "submit-search": _vm.submitSearch },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    this.$route.name != "confirm-boxset"
                      ? _c(
                          "div",
                          { staticClass: "panel-dark-blue voucherAlert" },
                          [
                            _c(
                              "div",
                              { staticClass: "top" },
                              [
                                _c("SvgExclamation"),
                                _c("div", [_c("CopyScadenza")], 1),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.boxsetId !== 0
                      ? _c(
                          "div",
                          { staticClass: "infoBoxsetPurchaseWrapper" },
                          [
                            _c(
                              "p",
                              [
                                _vm._v(
                                  "\n                    Acquistando questo voucher potrai prenotare un soggiorno di " +
                                    _vm._s(_vm.nightsText) +
                                    " in una qualsiasi località " +
                                    _vm._s(_vm.localitaText) +
                                    " di tua preferenza.\n                    "
                                ),
                                _c("CopyScadenza", {
                                  attrs: {
                                    default:
                                      "Il Salabam è utilizzabile entro {value} mesi dalla data di acquisto, potrai quindi acquistare ora e scegliere con calma la destinazione la struttura in cui effettuare il soggiorno.",
                                    promoMonths:
                                      "Il Salabam è utilizzabile entro {value} mesi dalla data di acquisto, potrai quindi acquistare ora e scegliere con calma la destinazione la struttura in cui effettuare il soggiorno.",
                                    promoFixed:
                                      "Il Salabam è utilizzabile entro il {value}, potrai quindi acquistare ora e scegliere con calma la destinazione la struttura in cui effettuare il soggiorno.",
                                    extra:
                                      "Ti ricordiamo che la data di scadenza indica l'ultima data possibile selezionabile come check-in.",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.mixinGetIntegrationInfo().sso
                              ? _c(
                                  "div",
                                  { staticClass: "infoBoxsetPurchase" },
                                  [
                                    _c(
                                      "b-alert",
                                      { attrs: { show: "", variant: "info" } },
                                      [
                                        _vm._v(
                                          "\n                        Acquista ora e riceverai dopo pochi istanti un'email con le istruzioni ed il link per effettuare la prenotazione del soggiorno.    \n                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _c("div", [
                          _vm.boxset.email != "gift@salabam.com"
                            ? _c(
                                "div",
                                { staticClass: "infoBoxsetPurchase mt-4" },
                                [
                                  _c(
                                    "b-alert",
                                    { attrs: { show: "", variant: "success" } },
                                    [
                                      _vm._v(
                                        "\n                        Abbiamo appena inviato all'email "
                                      ),
                                      _c(
                                        "strong",
                                        { staticClass: "secondary" },
                                        [_vm._v(_vm._s(_vm.boxset.email))]
                                      ),
                                      _vm._v(
                                        " la conferma d'acquisto, il codice del voucher e le istruzioni per effettuare la prenotazione del viaggio.\n                    "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "panel panel-gray text-center" },
                            [
                              _c("br"),
                              _c("span", { staticClass: "smallText" }, [
                                _vm._v(
                                  "Se non dovessi ricevere l'email controlla anche la cartella spam o contatta l'assistenza."
                                ),
                              ]),
                              _c("br"),
                              _c("br"),
                              _c("br"),
                              _c(
                                "b-row",
                                { staticClass: "confirmButtons" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-5 mb-md-0",
                                      attrs: { sd: "12", md: "6" },
                                    },
                                    [
                                      _c("p", { staticClass: "text-center" }, [
                                        _c("strong", [
                                          _vm._v("Stampa il tuo voucher:"),
                                        ]),
                                      ]),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "btn-block",
                                          on: {
                                            click: function ($event) {
                                              return _vm.print()
                                            },
                                          },
                                        },
                                        [_vm._v("Stampa!")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { sd: "12", md: "6" } },
                                    [
                                      _c("p", { staticClass: "text-center" }, [
                                        _c("strong", [
                                          _vm._v(
                                            "Oppure usa subito il tuo voucher:"
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "p",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "btn-block",
                                              on: {
                                                click: _vm.logoutAndRedirect,
                                              },
                                            },
                                            [_vm._v("Prenota ora!")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.redirect
                                ? _c("p", { staticClass: "text-center" }, [
                                    _c("br"),
                                    _c("br"),
                                    _c("strong", [
                                      _vm._v(
                                        "Vuoi acquistare un altro voucher Salabam?"
                                      ),
                                    ]),
                                    _c("br"),
                                    _c("span", { staticClass: "smallText" }, [
                                      _vm._v(
                                        "\n                            Per effettuare un altro acquisto devi per prima cosa fare un nuovo login sul tuo portale welfare e \n                            scegliere ancora Salabam tornando così su questo sito per il nuovo acquisto\n                        "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("br"),
                            ],
                            1
                          ),
                        ]),
                    _c(
                      "div",
                      {
                        staticClass: "purchaseValuePropositionsCheckoutWrapper",
                      },
                      [
                        _c("PurchaseValuePropositions", {
                          attrs: { className: "boxset-" + _vm.band },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "termsShortCheckoutWrapper" },
                      [_c("TermsShort")],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "valuePropositionsCheckoutWrapper" },
                      [
                        _c(
                          "div",
                          { staticClass: "panel panel-gray" },
                          [_c("ValuePropositions")],
                          1
                        ),
                        _c("br"),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }